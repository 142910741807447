import ArticleContent, { imagePath } from '../ArticleContent';

const StatusReportEntryArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          title: 'Activating Report Entry',
          steps: [
            {
              step: 'To activate the report entry, simply click the action button on the right side of the report entry, then click "activate".',
              image: `${imagePath}/activate-report-entry.png`,
            },
          ],
        },
        {
          title: 'Reopening Report Entry',
          steps: [
            {
              step: 'To re-open the report entry, simply click the action button on the right side of the report entry, then click "reopen". The "reopen" action will only show when the status of the report entry is either "Closed" or "Active"',
              image: `${imagePath}/reopen-report-entry-1.png`,
            },
            {
              step: '',
              image: `${imagePath}/reopen-report-entry-2.png`,
            },
          ],
        },
        {
          title: 'Closing Report Entry',
          steps: [
            {
              step: 'To close the report entry, simply click the action button on the right side of the report entry, then click "close".',
              image: `${imagePath}/close-report-entry.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default StatusReportEntryArticle;
