import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Back = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  padding: 7px 10px;
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  color: #069045;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid #069045;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 #069045;

  &:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 #069045;
  }

  &:active {
    transform: scale(0.9);
  }
`;

type BackButtonProps = {
  label: string;
};

const BackButton: React.FC<BackButtonProps> = (props: BackButtonProps) => {
  let navigate = useNavigate();
  return (
    <>
      <Back onClick={() => navigate(-1)}>{props.label ? props.label : 'Back'}</Back>
    </>
  );
};

export default BackButton;
