import {
  AssessmentBatchModel,
  AssessmentCommentModel,
  AssessmentSectionModel,
  FacilityAssessmentModel,
} from 'model/Entities';
import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import AssessmentSection from 'screens/Monitor/AssessmentDetails/AssessmentSection';
import CustomTabs from './CustomTabs';
import TableOfContents from 'screens/Monitor/AssessmentDetails/TableOfContents';
import { tokens } from 'context/theme.context';

/* eslint-disable react-hooks/exhaustive-deps */

export const flatSections = (item: any) => {
  if (item.type === 'section') {
    return item.section_body.flatMap((body: any) => flatSections(body));
  }
  if (item.type === 'sub-section') {
    return item.section_content.flatMap((body: any) => flatSections(body));
  }
  if (item.type === 'parent') {
    return item.children.flatMap((child: any) => flatSections(child));
  }
  if (item.type === 'conditional-field') {
    const fields = [
      {
        field_code: item.field_code,
        field_name: item.field_name,
        response: item.response,
      },
    ];

    return [...fields, ...item.children.flatMap((child: any) => flatSections(child))];
  }
  if (item.type === 'table') {
    if (item.table_type === 'simple-table') {
      return item.rows.flatMap((row: any) => flatSections(row));
    } else {
      return item.rows.flatMap((row: any) => {
        return item.columns.flatMap((col: any) => {
          if (col.type === 'parent') {
            return col.children.map((child: any) => {
              const key = col.name + ' | ' + child.name;
              return {
                field_code: row.field_code + '-' + child.code,
                field_name: row.field_name + ' - ' + child.name,
                response: row.response ? row.response[key] : '',
              };
            });
          }
          return {
            field_code: row.field_code + '-' + col.code,
            field_name: row.field_name + ' - ' + col.name,
            response: row.response ? row.response[col.name] : '',
          };
        });
      });
    }
  }

  return {
    field_code: item.field_code,
    field_name: item.field_name,
    response: item.response,
  };
};

type IndicatorViewerProps = {
  assessment?: FacilityAssessmentModel;
  init_sections: string;
  defaultTab?: number;
  setExportRes?: (res: any) => void;
  comments?: AssessmentCommentModel[];
  getComments?: () => void;
  fromReportingTool?: boolean;
  fromPreview?: boolean;
  batch?: AssessmentBatchModel;
};

const IndicatorViewer: React.FC<IndicatorViewerProps> = ({
  assessment,
  init_sections,
  setExportRes,
  defaultTab,
  comments,
  getComments,
  fromReportingTool,
  fromPreview,
  batch,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const sectionRef = useRef<HTMLDivElement | null>(null);

  const [sections, setSections] = useState<AssessmentSectionModel[]>([]);
  const [sectionTabs, setSectionTabs] = useState<any>([]);
  const [selectedSectionNumber, setSelectedSectionNumber] = useState(defaultTab ?? 0);
  useEffect(() => {
    setSelectedSectionNumber(defaultTab ?? 0);
  }, [defaultTab]);

  useEffect(() => {
    if (init_sections) {
      const sections: AssessmentSectionModel[] = JSON.parse(init_sections);
      setSections(sections);
      const sectionTabs: any = sections.map((section) => {
        return {
          label: 'Section ' + section.section_number,
          content: (
            <Box style={{ position: 'relative' }}>
              <AssessmentSection
                assessment={assessment}
                section={section}
                comments={comments}
                refreshComment={getComments}
                fromReportingTool={fromReportingTool}
                fromPreview={fromPreview}
                batch={batch}
              />
            </Box>
          ),
        };
      });
      setSectionTabs(sectionTabs);
    } else {
      setSectionTabs([]);
    }
  }, [init_sections, comments, assessment, batch]);

  useEffect(() => {
    const responses = sections.flatMap((section) => {
      return section.section_body.flatMap((body: any) => {
        return flatSections(body);
      });
    });
    setExportRes && setExportRes(responses);
  }, [sections]);

  useEffect(() => {
    // Call the scroll function when the selected section number changes
    handleScrollToSection();
  }, [selectedSectionNumber]);

  const handleScrollToSection = () => {
    sectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box mt="20px">
      <Box sx={{ m: '20px 0' }} ref={sectionRef}>
        <TableOfContents sections={sections} setSelected={setSelectedSectionNumber} />
        <CustomTabs
          tabs={sectionTabs}
          selected={selectedSectionNumber}
          tabBackgroundColor={fromPreview ? colors.solidHoverBackground : undefined}
        />
      </Box>
    </Box>
  );
};

export default IndicatorViewer;
