import ArticleContent, { imagePath } from '../ArticleContent';

const RespondReportArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          title: 'Facility Staff Account',
          steps: [
            {
              step: 'In responding to Facility report entries, the facility staff account will be used (which are added through facility section of the page).',
              image: `${imagePath}/facility-facility-staff.png`,
            },
          ],
        },
        {
          title: 'Responding to Reports',
          steps: [
            {
              step: '1. Login to Facility Staff account.',
            },
            {
              step: '2. The page will open in the "Reports" section, where report entries can be answered after logging into the facility staff account. The user can answer through the "Reports to be completed" or "Report entries" table. The first table shows list of the facility report entries, while the second shows the reports facility where report entries are compiled:',
              image: `${imagePath}/facility-staff-1.png`,
            },
            {
              step: '3. To answer the facility report entries, the user can click the facility report entry on "Reports to be completed" table then click edit from the drawer, or click the report on "Report Entries (Internal)" table and click the hyperlinked month in Period column then "edit" button.',
              image: `${imagePath}/facility-staff-2.png`,
            },
            { step: '', image: `${imagePath}/facility-staff-3.png` },
            { step: '', image: `${imagePath}/facility-staff-4.png` },
            {
              step: '4. Upon clicking "edit" the user can now answer the facility report entry form.',
            },
          ],
        },
        {
          title: 'Submitting reports',
          steps: [
            {
              step: 'To submit the reports, the user must first finish answering the form. To check if there are no missed fields to answer, the user can check the "check missing items" checkbox. If there are no missing items, the submit button will automatically enable which indicates that the user can now submit the report entry.',
              image: `${imagePath}/facility-staff-5.png`,
            },
            { step: '', image: `${imagePath}/facility-staff-6.png` },
          ],
        },
      ]}
    />
  );
};

export default RespondReportArticle;
