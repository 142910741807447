import { DashboardTemplate } from '.';
import { EasternVisayasAHDPDashboard1 } from 'data/Eastern Visayas/dashboard/AHDP/chartConfiguration_AHDP_V1';
import { EasternVisayasDashboard1 } from 'data/Eastern Visayas/dashboard/FRESAAR/chartConfiguration_EV';

export const eastern_visayas_dashboards: DashboardTemplate[] = [
  {
    dashboard_code: 'ev_dashboard_1',
    dashboard_name: 'Eastern Visayas FRESAAR Dashboard',
    template: EasternVisayasDashboard1,
  },
  {
    dashboard_code: 'ev_dashboard_ahdp_1',
    dashboard_name: 'Eastern Visayas AHDP Dashboard',
    template: EasternVisayasAHDPDashboard1,
  },
];
