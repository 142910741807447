import { DashboardWidget } from 'screens/Dashboard/charts/chartDataProcessor';
import { aklan_dashboards } from './Aklan';
import { caraga_dashboards } from './CARAGA';
import { eastern_visayas_dashboards } from './EasternVisayas';

export interface DashboardTemplate {
  dashboard_code: string;
  dashboard_name: string;
  template: DashboardWidget[];
  pcf?: [any, any, any];
}

export interface PCFItem {
  item_number: number;
  required_response: string | string[];
  items: string[];
}

export const region_dashboards: any = {
  '0600000000': aklan_dashboards,
  '1600000000': caraga_dashboards, //CARAGA
  '0800000000': eastern_visayas_dashboards, // Eastern Visayas
  '1100000000': [], //Davao
};
