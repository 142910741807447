import { Box, Typography } from '@mui/material';
import { pcf_equipment_sample, pcf_items_sample } from 'data/Aklan/dashboard/AklanPilot/pcf_Sample';

import { DashboardWidget } from 'screens/Dashboard/charts/chartDataProcessor';
import { formatNumber } from 'utils';

const COLORS = {
  GREEN: '#81ca9c',
  YELLOW: '#ffc658',
  ORANGE: '#f6914f',
  RED: '#f6665f',
  BLUE: '#54BAD1',
  PURPLE: '#8784d8'
};

export const AklanSampleDashboard: DashboardWidget[] = [
  {
    type: 'pie',
    title: 'Facilities Assessed',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 3 },
    xAxisDataKeys: [{ key: 'Facility Type' }],
    yAxisDataKeys: [
      { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
      { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
      { key: 'Infirmary', chartColor: COLORS.BLUE },
      { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
    ],
  },
  {
    type: 'bar',
    title: 'Facilities Assessed per Municipality',
    label: { xAxisLabel: 'Municipality', yAxisLabel: 'Count' },
    containerDimnesion: { colSpan: 9 },
    xAxisDataKeys: [{ key: 'Municipality' }],
    useXAxisDataKeyResponseAsXAxis: true,
    yAxisDataKeys: [{ key: 'Count',  chartColor: COLORS.BLUE }],
    yAxisBreakdown: {
      breakdownKey: 'Facility Type',
      yAxisBreakdownKeys: [
        { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
        { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
        { key: 'Infirmary', chartColor: COLORS.BLUE },
        { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
      ],
    },
  },
  {
    type: 'bar',
    title: 'Service Capability of RHU',
    description: <Box display="flex" justifyContent="flex-end" gap="30px" width="100%">
      <Typography color={COLORS.GREEN}>Green = 80 - 100% Yes</Typography>
      <Typography color={COLORS.YELLOW}>Yellow = 50 - 79% Yes</Typography>
      <Typography color={COLORS.ORANGE}>Orange = 25 - 49% Yes</Typography>
      <Typography color={COLORS.RED}>Red = 0 - 24% Yes</Typography>
    </Box>,
    label: { xAxisLabel: 'Services', yAxisLabel: '% of Facilities' },
    containerDimnesion: { colSpan: 12 },
    // facilityTypeFilter: ['Rural Health Unit'],
    displayAsPercentage: true,
    hideLegend: true,
    xAxisDataKeys: [
      { key: 'Antenatal Care' },
      { key: 'Delivery Services' },
      { key: 'Postnatal Care' },
      { key: 'Newborn Care' },
      { key: 'Family Planning' },
      { key: 'Child Vaccination' },
      { key: 'Child Growth Monitoring Services' },
      { key: 'Child Preventive and Curative Care Services' },
      { key: 'Adolescent Health Services' },
      { key: 'Laboratory Services' },
    ],
    yAxisDataKeys: [{ key: 'Yes', chartColor: COLORS.GREEN }],
    conditionalBarColorGetter: (value) => {
      if (value >= 80) return COLORS.GREEN;
      if (value >= 50) return COLORS.YELLOW;
      if (value >= 25) return COLORS.ORANGE;
      return COLORS.RED;
    },
    dataPreprocessor: (data) => {
      const antenatalCareAvailableFields = ['083A', '083B', '083C']; // TODO: should be 084A - 084M, 086A - 086D
      const antenatalCareYesFields = ['086', '087']; // TODO: should be 087, 088

      const deliveryAvailableFields = ['088A', '088B', '088C', '088D', '088E', '088F']; //TODO: should be 089A - 089S,
      const deliveryYesFields = ['089A', '089B', '089C']; //090A - 090C

      const postnatalCareYesFields = ['092A', '092B', '092C', '093A', '093B', '093C']; // 091A - 091J, 092A-C
      const newbornCareYesFields = ['094A', '094B']; // 093A-O, 094
      const facilityPlanningYesFields = ['096A', '096B']; // 095A-I, 096, 097
      const childVaccinationYesFields = ['099A', '099B']; // 098A-H, 100, 101, 102
      const childMonitoringYesFields = ['104A', '104B']; // 103A-C, 105, 106
      const childPreventiveYesFields = ['108A', '108B']; // 107A-L, 109A-E, 110
      const adolescentHealthYesFields = ['111A', '111B']; // 111A-E, 112A-C, 113
      const laboratoryAvailableFields = ['114A', '114B']; // 114A-O

      const getValue = (row: any, yesFields: string[], availableFields?: string[]) => {
        let count = 0;
        let totalIndicators = yesFields.length + (availableFields ? availableFields.length : 0);
        count += yesFields.reduce((result, field) => {
          return result + (row[field] === 'No' ? 0 : 1);
        }, 0);
        if (availableFields) {
          count += availableFields.reduce((result, field) => {
            return result + (row[field] === 'Not Available' ? 0 : 1);
          }, 0);
        }
        // 100% Yes or Available
        return count === totalIndicators ? 'Yes' : 'No';
      };

      data.forEach((row) => {
        row['Antenatal Care'] = getValue(row, antenatalCareYesFields, antenatalCareAvailableFields);
        row['Postnatal Care'] = getValue(row, postnatalCareYesFields);
        row['Newborn Care'] = getValue(row, newbornCareYesFields);
        row['Family Planning'] = getValue(row, facilityPlanningYesFields);
        row['Child Vaccination'] = getValue(row, childVaccinationYesFields);
        row['Child Growth Monitoring Services'] = getValue(row, childMonitoringYesFields);
        row['Child Preventive and Curative Care Services'] = getValue(row, childPreventiveYesFields);
        row['Adolescent Health Services'] = getValue(row, adolescentHealthYesFields);
        row['Laboratory Services'] = getValue(row, [], laboratoryAvailableFields);

        // For RHU with SBF only
        row['Delivery Services'] =
          row[deliveryAvailableFields[0]] === 'N/A'
            ? 'Not Applicable'
            : getValue(row, deliveryYesFields, deliveryAvailableFields);
      });
      return data;
    },
  },
  {
    type: 'bar',
    title: 'PhilHealth Accreditation',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: '% of Facilities with Packages' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'count',
    displayAsPercentage: true,
    stacked: true,
    xAxisDataKeys: [
      { key: '018A', displayName: 'Konsulta Package' },
      { key: '018B', displayName: 'Maternity Care Package' },
      { key: '018C', displayName: 'Newborn Care Package' },
      { key: '018D', displayName: 'Family Planning Package' },
      { key: '018E', displayName: 'Z-Benefit Package: Dialysis' },
      { key: '018F', displayName: 'OHAT' },
      { key: '018G', displayName: 'TB-DOTS' },
      { key: '018H', displayName: 'Animal Bite Treatment Center' },
      { key: '018I', displayName: 'Out-Patient Malaria Package' },
      { key: '018J', displayName: 'CCIBP' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.GREEN },
      { key: 'No', chartColor: COLORS.RED },
    ],
  },
  {
    type: 'bar',
    title: 'PhilHealth Accreditation',
    description: 'Number of Accredited Facilities per Facility Type',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'count',
    xAxisDataKeys: [
      { key: '018A', displayName: 'Konsulta Package' },
      { key: '018B', displayName: 'Maternity Care Package' },
      { key: '018C', displayName: 'Newborn Care Package' },
      { key: '018D', displayName: 'Family Planning Package' },
      { key: '018E', displayName: 'Z-Benefit Package: Dialysis' },
      { key: '018F', displayName: 'OHAT' },
      { key: '018G', displayName: 'TB-DOTS' },
      { key: '018H', displayName: 'Animal Bite Treatment Center' },
      { key: '018I', displayName: 'Out-Patient Malaria Package' },
      { key: '018J', displayName: 'CCIBP' },
    ],
    yAxisDataKeys: [{ key: 'Yes', chartColor: COLORS.BLUE }],
    yAxisBreakdown: {
      breakdownKey: 'Facility Type',
      yAxisBreakdownKeys: [
        { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
        { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
        { key: 'Infirmary', chartColor: COLORS.BLUE },
        { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
      ],
    },
  },
  {
    type: 'table',
    title: 'Health Workforce',
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12, rowSpan: 3 },
    aggregation: 'sum',
    // facilityTypeFilter: ['Rural Health Unit', 'Safe Birthing Facility'],
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: '007A', displayName: 'Population', type: 'number' },
      { key: '007C', displayName: 'Household', type: 'number' },
      { key: 'Doctors', type: 'number' },
      { key: 'Nurses', type: 'number' },
      { key: 'Midwives', type: 'number' },
      { key: 'Dentists', type: 'number' },
      { key: 'Pharmacists', type: 'number' },
      { key: 'IT', type: 'number' },
      { key: 'BHWs', type: 'number' },

      { key: 'Doctor-Population Ratio' },
      { key: 'Nurse-Population Ratio' },
      { key: 'Midwife-Population Ratio' },
      { key: 'Dentist-Population Ratio' },
      { key: 'Pharmacist-Population Ratio' },
      { key: 'BHW-Household Ratio' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'Population',
        'Household',
        'Doctors',
        'Doctor-Population Ratio',
        'Nurses',
        'Nurse-Population Ratio',
        'Midwives',
        'Midwife-Population Ratio',
        'Pharmacists',
        'Pharmacist-Population Ratio',
        'Dentists',
        'Dentist-Population Ratio',
        'BHWs',
        'BHW-Household Ratio',
        'IT',
      ],
      sortBy: 'Facility Name',
    },
    dataPreprocessor: (data) => {
      const doctorSourceFields = ['128A-1', '128A-2', '128A-3', '128A-4', '128A-5', '128A-6'];
      const nursesSourceFields = ['128B-1', '128B-2', '128B-3', '128B-4', '128B-5', '128B-6'];
      const midwivesSourceFields = ['128C-1', '128C-2', '128C-3', '128C-4', '128C-5', '128C-6'];
      const dentistsSourceFields = ['128E-1', '128E-2', '128E-3', '128E-4', '128E-5', '128E-6'];
      const pharmacistsSourceFields = ['128K-1', '128K-2', '128K-3', '128K-4', '128K-5', '128K-6'];
      const itSourceFields = ['128H-1', '128H-2', '128H-3', '128H-4', '128H-5', '128H-6'];
      const bhwSourceFields = ['128G-1', '128G-2', '128G-3', '128G-4', '128G-5', '128G-6']; //TODO: '129A-1', '129A-2'

      const counter = (row: any, sourceFields: string[]) => {
        return sourceFields.reduce((result, field) => {
          const response = row[field];
          return result + (isNaN(parseFloat(response)) ? 0 : parseFloat(response));
        }, 0);
      };

      const getRatio = (count: number, population: number) => {
        return count ? `1 : ${formatNumber(population / count, 0)}` : 'N/A';
      };
      
      data.forEach((row) => {
        row['Doctors'] = counter(row, doctorSourceFields);
        row['Nurses'] = counter(row, nursesSourceFields);
        row['Midwives'] = counter(row, midwivesSourceFields);
        row['Dentists'] = counter(row, dentistsSourceFields);
        row['Pharmacists'] = counter(row, pharmacistsSourceFields);
        row['IT'] = counter(row, itSourceFields);
        row['BHWs'] = counter(row, bhwSourceFields);

        const population = row['007A']; // TODO: change to 007D
        const household = row['007C']; // 007E
        row['Doctor-Population Ratio'] = getRatio(row['Doctors'], population);
        row['Nurse-Population Ratio'] = getRatio(row['Nurses'], population);
        row['Midwife-Population Ratio'] = getRatio(row['Midwives'], population);
        row['Pharmacist-Population Ratio'] = getRatio(row['Pharmacists'], population);
        row['Dentist-Population Ratio'] = getRatio(row['Dentists'], population);
        row['BHW-Household Ratio'] = getRatio(row['BHWs'], household);
      });
      return data;
    },
  },
  {
    type: 'bar',
    title: 'Health Worker Training',
    description: 
    <Box>
      <Box display="flex" justifyContent="space-between" >
        <Typography>Percentage of facilities with at least one trained personnel</Typography>
        <Box display="flex" justifyContent="space-between" width="40%">
          <Typography color={COLORS.GREEN}>Green = 90 - 100% Yes</Typography>
          <Typography color={COLORS.YELLOW}>Yellow = 50 - 89% Yes</Typography>
          <Typography color={COLORS.RED}>Red = 0 - 49% Yes</Typography>
        </Box>
      </Box> 
    </Box>,
    label: { xAxisLabel: 'Services', yAxisLabel: '% of Facilities' },
    containerDimnesion: { colSpan: 12 },
    // facilityTypeFilter: ['Rural Health Unit'],
    displayAsPercentage: true,
    hideLegend: true,
    stacked: true,
    xAxisDataKeys: [
      { key: 'Antenatal Care' },
      { key: 'Delivery Services' },
      { key: 'Postnatal Care' },
      { key: 'Family Planning' },
      { key: 'Child Health Services' },
      { key: 'Adolescent Health Services' },
    ],
    yAxisDataKeys: [{ key: 'Yes', chartColor: COLORS.BLUE }],
    conditionalBarColorGetter: (value) => {
      if (value >= 90) return COLORS.GREEN;
      if (value >= 50) return COLORS.YELLOW;
      return COLORS.RED;
    },
    dataPreprocessor: (data) => {
      const antenatalCareYesFields = ['132A-7', '132B-7', '132C-7', '132D-7']; // TODO: should be 134A-7 A-D, 135A-7 A-C, 136A-B, 137A-1
      const deliveryAvailableFields = ['136A-7', '136B-7', '136C-7', '136D-7']; //TODO: should be 138A-7 A-D, 139A-7 A-D
      const postnatalCareYesFields = ['138A-7', '138B-7']; //  140A-7 A-B,
      const familyPlanningYesFields = ['139A-7', '139B-7']; // 141A-7 A-E, 142A-7
      const childVaccinationYesFields = ['141A-7', '141B-7']; // 143A-7 A-J
      const adolescentHealthYesFields = ['142A-7', '142B-7']; // 144A-7 A-J

      const getValue = (row: any, yesFields: string[]) => {
        let totalIndicators = yesFields.length;
        let count = yesFields.reduce((result, field) => {
          const response = row[field];
          const total = isNaN(parseFloat(response)) ? 0 : parseFloat(response);
          return result + (total > 0 ? 1 : 0);
        }, 0);

        // 100% Yes or Available
        return count === totalIndicators ? 'Yes' : 'No';
      };

      data.forEach((row) => {
        row['Antenatal Care'] = getValue(row, antenatalCareYesFields);
        row['Delivery Services'] = getValue(row, deliveryAvailableFields);
        row['Postnatal Care'] = getValue(row, postnatalCareYesFields);
        row['Family Planning'] = getValue(row, familyPlanningYesFields);
        row['Child Health Services'] = getValue(row, childVaccinationYesFields);
        row['Adolescent Health Services'] = getValue(row, adolescentHealthYesFields);
      });
      return data;
    },
  },
  {
    type: 'bar',
    title: 'Budget for Health',
    label: { xAxisLabel: 'Municipality', yAxisLabel: 'Budget' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'sum',
    // facilityTypeFilter: ['Rural Health Unit'],
    xAxisDataKeys: [{ key: 'Municipality' }],
    useXAxisDataKeyResponseAsXAxis: true,
    stacked: true,
    yAxisDataKeys: [
      { key: '144B', displayName: 'Safe Motherhood', chartColor: COLORS.BLUE }, //TODO: change to 146B-1
      { key: '144C', displayName: 'Family Planning', chartColor: COLORS.RED },
      { key: '144D', displayName: 'Child Health', chartColor: COLORS.GREEN },
      { key: '144E', displayName: 'Nutrition', chartColor: COLORS.ORANGE },
      { key: '144F', displayName: 'Adolescent Health', chartColor: COLORS.PURPLE },
      { key: '144G', displayName: 'Oral Health', chartColor: COLORS.YELLOW },
      { key: '144H', displayName: 'ICT / EMR', chartColor: '#a4de6c' },
      { key: '144I', displayName: 'Other programs', chartColor: '#54BAD1' },
    ],
  },
  {
    type: 'table',
    title: 'Medicine Availability',
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12, rowSpan: 3 },
    aggregation: 'sum',
    facilityTypeFilter: ['Rural Health Unit', 'Safe Birthing Facility'],
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: 'Antenatal Care', type: 'percentage' },
      { key: 'Delivery Services', type: 'percentage' },
      { key: 'Postnatal Care', type: 'percentage' },
      { key: 'Newborn Care', type: 'percentage' },
      { key: 'Family Planning', type: 'percentage' },
      { key: 'Child Vaccination', type: 'percentage' },
      { key: 'Child Preventive and Curative Care Services', type: 'percentage' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'Antenatal Care',
        'Delivery Services',
        'Postnatal Care',
        'Newborn Care',
        'Family Planning',
        'Child Vaccination',
        'Child Preventive and Curative Care Services',
      ],
      sortBy: 'Facility Name',
    },
    dataPreprocessor: (data) => {
      const antenatalCareMedicines = ['147A-1', '147B-1', '147C-1']; //TODO: 149A-1
      const deliveryMedicines = ['148A-1', '148B-1', '148C-1']; //TODO: 150A-1
      const postnatalCareMedicines = ['149A-1', '149B-1', '149C-1']; //TODO: 151A-1
      const newbornCareMedicines = ['150A-1', '150B-1', '150C-1']; //TODO: 152A-1
      const familyPlanningCareMedicines = ['151A-1', '151B-1', '151C-1']; //TODO: 153A-1
      const childVaccinationCareMedicines = ['152A-1', '152B-1', '152C-1']; //TODO: 154A-1
      const childPreventiveCareMedicines = ['153A-1', '153B-1', '153C-1']; //TODO: 155A-1

      const getValue = (row: any, medicines: string[]) => {
        let totalIndicators = medicines.length;
        const count = medicines.reduce((result, field) => {
          return (
            result + (row[field] === 'Available, but expired' || row[field] === 'No stock (Prescribe or Refer)' ? 0 : 1)
          );
        }, 0);

        return `${formatNumber((count / totalIndicators) * 100, 0)}%`;
      };

      data.forEach((row) => {
        row['Antenatal Care'] = getValue(row, antenatalCareMedicines);
        row['Postnatal Care'] = getValue(row, postnatalCareMedicines);
        row['Newborn Care'] = getValue(row, newbornCareMedicines);
        row['Family Planning'] = getValue(row, familyPlanningCareMedicines);
        row['Child Vaccination'] = getValue(row, childVaccinationCareMedicines);
        row['Child Preventive and Curative Care Services'] = getValue(row, childPreventiveCareMedicines);

        // For RHU with SBF only
        row['Delivery Services'] = row[deliveryMedicines[0]] === 'N/A' ? 'N/A' : getValue(row, deliveryMedicines);
      });
      return data;
    },
  },
  {
    type: 'bar',
    title: 'Presence of DOH Policies in RHUs',
    label: { xAxisLabel: 'DOH Policies', yAxisLabel: '% of Facilities' },
    containerDimnesion: { colSpan: 12 },
    // facilityTypeFilter: ['Rural Health Unit'],
    displayAsPercentage: true,
    stacked: true,
    xAxisDataKeys: [
      { key: 'Maternal Care' },
      { key: 'Oral Health' },
      { key: 'Newborn, Child, and Adolescent Health' },
      { key: 'Nutrition' },
      { key: 'Population-based Primary Care Services' },
      { key: 'Individual-based Primary Care Services' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.BLUE },
      { key: 'No', chartColor: COLORS.RED },
    ],
    dataPreprocessor: (data) => {
      const maternalCareYesFields = ['012A', '012B', '012C']; // TODO: should be 012A-J
      const oralHealthYesFields = ['013A', '013B', '013C'];
      const ncaHealthYesFields = ['014A', '014B', '014C']; // TODO: should be 014A-E
      const nutritionYesFields = ['015A', '015B', '015C']; // TODO: should be 015A-E
      const populationCareYesFields = ['016A', '016B', '016C']; // TODO: should be 016A-D
      const individualCareYesFields = ['017A', '017B', '017C']; // TODO: should be 017A-G

      const getValue = (row: any, yesFields: string[]) => {
        let totalIndicators = yesFields.length;
        let count = yesFields.reduce((result, field) => {
          return result + (row[field] === 'No' ? 0 : 1);
        }, 0);

        // 100% Yes or Available
        return count === totalIndicators ? 'Yes' : 'No';
      };

      data.forEach((row) => {
        row['Maternal Care'] = getValue(row, maternalCareYesFields);
        row['Oral Health'] = getValue(row, oralHealthYesFields);
        row['Newborn, Child, and Adolescent Health'] = getValue(row, ncaHealthYesFields);
        row['Nutrition'] = getValue(row, nutritionYesFields);
        row['Population-based Primary Care Services'] = getValue(row, populationCareYesFields);
        row['Individual-based Primary Care Services'] = getValue(row, individualCareYesFields);
      });
      return data;
    },
  },
  {
    type: 'bar',
    title: 'EMR Functionality Status of RHUs',
    label: { xAxisLabel: 'EMR Functionality', yAxisLabel: '% of Facilities' },
    aggregation: 'count',
    displayAsPercentage: true,
    stacked: true,
    // facilityTypeFilter: ['Rural Health Unit'],
    xAxisDataKeys: [
      { key: '167A', displayName: 'Patients Consultation Recording' }, //169A-D
      { key: '167B', displayName: 'Inventory of Logistics' },
      { key: '167C', displayName: 'Environmental' },
      { key: '167D', displayName: 'Financial for PhilHealth Claims' },
      { key: '169A', displayName: 'FHSIS Report Submission' }, //171A
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.BLUE },
      { key: 'No', chartColor: COLORS.RED },
    ],
  },
  {
    type: 'bar',
    title: 'DOH Licenses',
    label: { xAxisLabel: 'DOH Licenses', yAxisLabel: 'No. of Facilities' },
    xAxisDataKeys: [
      { key: '202', displayName: 'Safe Birthing Facility' }, //TODO: 205
      { key: '203', displayName: 'Pharmacy' }, //TODO: 206
      { key: '204', displayName: 'Radiologic Services' }, //TODO: 207
      { key: '205', displayName: 'Ambulance' }, //TODO: 208
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.BLUE },
      { key: 'No', chartColor: COLORS.RED },
      { key: 'Service outsourced', chartColor: COLORS.GREEN },
    ],
  },
  {
    type: 'bar',
    title: 'Outcome Indicators for Mortality',
    label: { xAxisLabel: 'Mortality', yAxisLabel: 'No. of Deaths' },
    aggregation: 'sum',
    xAxisDataKeys: [
      { key: '191', displayName: 'Maternal Deaths' }, //TODO: 194
      { key: '193', displayName: 'Neonatal Deaths' }, //TODO: 196
      { key: '195', displayName: 'Infant Deaths (< 1 year of age)' }, //TODO: 198
      { key: '197', displayName: 'Child Deaths' }, //TODO: 200
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
  },
  {
    type: 'bar',
    title: 'Outcome Indicators for Nutrition',
    label: { xAxisLabel: 'Nutrition', yAxisLabel: 'No. of Malnourished Children' },
    aggregation: 'sum',
    xAxisDataKeys: [
      { key: '198', displayName: '0-59 Months Wasting' }, //TODO: 201
      { key: '199', displayName: '0-59 Months SAM' }, //TODO: 202
      { key: '200', displayName: '0-59 Months Stunting' }, //TODO: 203
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.GREEN }],
  },
  {
    type: 'bar',
    title: 'PCF Compliance Status of RHUs',
    label: { xAxisLabel: 'Facility Name', yAxisLabel: 'PCF Compliance' },
    containerDimnesion: { colSpan: 12 },
    // facilityTypeFilter: ['Rural Health Unit', 'Safe Birthing Facility'],
    xAxisDataKeys: [{ key: 'Facility Code', displayName: 'Facility Name' }],
    aggregation: 'sum',
    useXAxisDataKeyResponseAsXAxis: true,
    yAxisDataKeys: [{ key: 'PCF Compliance Status', chartColor: COLORS.BLUE }],
    yAxisReferenceLine: {
      label: "Benchmark (90%)",
      yValue: 90,
    },
    addPercentSignOnly: true,
    customTooltipFormatter: (value: any) => formatNumber(value) + '%',
    dataPreprocessor: (data) => {
      const pcf_indicators = pcf_items_sample.flatMap((field) =>
        field.items.map((code: any) => {
          return { field_code: code, required_response: field.required_response ?? 'Yes' };
        })
      );
      const equipment = pcf_equipment_sample.flatMap((item: any) =>
        item.equipment.flatMap((field: any) =>
          field.field_code.map((code: any) => {
            return { field_code: code, required_response: field.required_response ?? 'Available & Functional' };
          })
        )
      );
      const getValue = (row: any) => {
        let totalIndicators = equipment.length + pcf_indicators.length;
        let count = pcf_indicators.reduce((result, field) => {
          return result + (!row[field.field_code] || row[field.field_code] === field.required_response ? 1 : 0);
        }, 0);
        count += equipment.reduce((result, field) => {
          return result + (!row[field.field_code] || row[field.field_code] === field.required_response ? 1 : 0);
        }, 0);

        // 100% Yes or Available
        return (count / totalIndicators) * 100;
      };

      data.forEach((row) => {
        row['PCF Compliance Status'] = getValue(row);
      });
      return data;
    },
  },
];
