/* eslint-disable react-hooks/exhaustive-deps */

import { Box, useTheme } from '@mui/material';
import { CustomContainer, CustomDrawer, CustomGridCell, Header } from 'components';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { formatDate, getStatusChip } from 'utils';

import { ApiQuery } from 'model/interface';
import CustomTable from 'components/CustomTable';
import FacilityReportEntries from 'screens/AssessmentFiles/FacilityReportEntries';
import { Link } from 'react-router-dom';
import { ReportEntryModel } from 'model/Entities';
import { getAllReportEntries } from 'api/report-entry';
import { getAllReportsByFacility } from 'api/report';
import { tokens } from 'context/theme.context';
import { useState } from 'react';

type ReportEntriesWithFacilityReportEntriesProps = {
  facilityID?: number;
  reportStatus?: string;
  customColumns?: GridColDef[];
};

const ReportEntriesWithFacilityReportEntries: React.FC<ReportEntriesWithFacilityReportEntriesProps> = ({
  facilityID,
  reportStatus,
  customColumns,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [reportEntryRows, setReportEntryRows] = useState<ReportEntryModel[]>([]);
  const [reportEntryRowCount, setReportEntryRowCount] = useState<number | undefined>(undefined);

  const [loading, setLoading] = useState(false);
  const [selectedReportEntryRow, setSelectedReportEntryRow] = useState<number>();
  const [selectedReportEntry, setSelectedReportEntry] = useState<ReportEntryModel>();
  const [clearReportEntrySelected, setClearReportEntrySelected] = useState(0);

  // Table Columns
  const reportEntryColumns: GridColDef[] = [
    {
      field: 'report_entry',
      sortable: false,
      headerName: 'Report',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.report.name}</CustomGridCell>,
    },
    {
      field: 'month',
      sortable: false,
      headerName: 'Period',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          <Link
            to={`/reports/${params.row.id}`}
            style={{
              color: colors.accent,
              textDecoration: 'none',
              width: '100%',
            }}
          >
            {params.row.month}
          </Link>
        </CustomGridCell>
      ),
    },
    {
      field: 'deadline',
      sortable: false,
      headerName: 'Deadline',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.deadline)}</CustomGridCell>,
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{getStatusChip(params.row.status, theme, colors)}</CustomGridCell>,
    },
  ];

  // Functions
  const getReportEntries = async (query?: ApiQuery) => {
    setLoading(true);
    try {
      const res = facilityID
        ? await getAllReportsByFacility(facilityID, { ...query })
        : await getAllReportEntries({ ...query, status: ['active', 'closed'] });
      setReportEntryRows(res.data.rows);
      setReportEntryRowCount(res.data.count);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectReportEntryRow = (selected: GridSelectionModel) => {
    if (selected.length && selectedReportEntryRow !== selected[0]) {
      const selectedID = selected[0] as number;
      setSelectedReportEntryRow(selectedID);
      setSelectedReportEntry(reportEntryRows.find((row) => row.id === selectedID));
    } else {
      setSelectedReportEntryRow(undefined);
      setSelectedReportEntry(undefined);
    }
  };

  const closeReportEntryDetails = () => {
    setSelectedReportEntryRow(undefined);
    setSelectedReportEntry(undefined);
    setClearReportEntrySelected((prev: number) => prev + 1);
  };

  return (
    <Box>
      <Box mt="20px">
        <CustomDrawer
          gridTemplateColumns="30% 1fr"
          showChild={!!selectedReportEntry}
          parentComponent={
            <CustomContainer p="25px">
              <CustomTable
                searchKeys="Name"
                columns={customColumns ?? reportEntryColumns}
                rows={reportEntryRows}
                rowCount={reportEntryRowCount ?? 0}
                loading={loading}
                getData={getReportEntries}
                isRowSelectable
                handleSelectRow={handleSelectReportEntryRow}
                clearSelected={clearReportEntrySelected}
                hideSearch={!!selectedReportEntry}
                headerComponent={
                  <Header
                    title={`Report Entries (Internal)`}
                    subtitle={
                      facilityID ? 'Click the row to see more details.' : 'Click the row to see completed reports.'
                    }
                    mb="0"
                  />
                }
              />
            </CustomContainer>
          }
          childComponent={
            selectedReportEntry ? (
              <FacilityReportEntries
                selectedReport={selectedReportEntry}
                handleClose={closeReportEntryDetails}
                facilityView={!!facilityID}
              />
            ) : (
              <></>
            )
          }
        />
      </Box>
    </Box>
  );
};

export default ReportEntriesWithFacilityReportEntries;
