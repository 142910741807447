import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Typography, useTheme } from '@mui/material';
import { ReactNode, useMemo } from 'react';

import { ReferenceLineProps } from './chartDataProcessor';
import { formatNumber } from 'utils';
import { tokens } from 'context/theme.context';

interface DashboardBarChartProps {
  data: any[];
  title: string;
  description?: ReactNode;
  xAxisLabel?: string;
  yAxisLabel?: string;
  chartColors: any;
  dataKeys: string[];
  colSpan?: number;
  rowSpan?: number;
  referenceLine?: ReferenceLineProps;
  stacked?: boolean;
  displayAsPercentage?: boolean;
  displayAsMultipleOfN?: number;
  displayDecimalPlaces?: number;
  displayTotal?: boolean;
  hideLegend?: boolean;
  tickFormatter?: (value: any, index?: number) => string;
  conditionalBarColorGetter?: (value: number, extra: any) => string;
  customTooltipFormatter?: (value: any) => string;
}

const DashboardBarChart: React.FC<DashboardBarChartProps> = ({
  title,
  description,
  xAxisLabel,
  yAxisLabel,
  data,
  chartColors,
  dataKeys,
  colSpan = 6,
  rowSpan = 2,
  referenceLine,
  stacked,
  tickFormatter,
  displayAsPercentage,
  displayTotal,
  conditionalBarColorGetter,
  customTooltipFormatter,
  hideLegend,
  displayAsMultipleOfN,
  displayDecimalPlaces = 0,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const unit = useMemo(() => {
    if (displayAsMultipleOfN === 1000000) return 'M';
    return '';
  }, [displayAsMultipleOfN]);

  const getColor = (item: any, key: string) => {
    if (conditionalBarColorGetter) {
      return conditionalBarColorGetter(item[key], item.extra);
    }
    return chartColors[key];
  };

  const tooltipFormatter = (value: any, name: any, item: any) => {
    if (customTooltipFormatter) {
      return customTooltipFormatter(value);
    }
    if (displayAsPercentage && item.payload.extra) {
      const data = item.payload.extra;
      const total = data.total ?? 0;
      const count = data[name] ?? 0;
      return displayAsPercentage ? `${value}% (${count} / ${total})` : value;
    }
    return formatNumber(value, displayDecimalPlaces);
  };

  const CustomXAxisTick = ({ x, y, payload }: any) => {
    if (payload && payload.value) {
      let columnWidth = 12 / colSpan;
      columnWidth = 1000 / columnWidth;
      columnWidth = columnWidth / data.length;
      return (
        <Text fontSize={'12px'} width={columnWidth} x={x} y={y} textAnchor="middle" verticalAnchor="start">
          {payload.value}
        </Text>
      );
    }
    return null;
  };

  const CustomBarLabel = ({ x, y, value }: any) => (
    <text x={x} y={y} dy={-10} textAnchor="middle" fill="#666">
      {value}
    </text>
  );

  return (
    <Box
      gridColumn={'span ' + colSpan}
      gridRow={'span ' + rowSpan}
      p="30px"
      sx={{ backgroundColor: colors.secondary_background }}
      borderRadius={2}
    >
      <Typography variant="h5" fontWeight="600">
        {title}
      </Typography>
      {description}

      <Box height="100%" paddingY="20px">
        <ResponsiveContainer width="100%">
          <BarChart data={data} barGap={10} barCategoryGap={20} margin={{ top: 0, right: 30, left: 20, bottom: 35 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tick={<CustomXAxisTick />} dataKey="chart-key" interval={0}>
              <Label value={xAxisLabel} position="center" dy={40} height={50} />
            </XAxis>

            <YAxis
              tickFormatter={tickFormatter}
              domain={displayAsPercentage ? [0, 100] : undefined}
              allowDecimals={false}
              unit={unit}
            >
              <Label value={yAxisLabel} position="insideBottomLeft" angle={-90} />
            </YAxis>

            <Tooltip
              formatter={(value, name, item) => {
                return tooltipFormatter(value, name, item);
              }}
              labelFormatter={(label, payload) => {
                return (
                  <Box>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>{label}</Typography>
                    {displayTotal && (
                      <Typography fontWeight="bold">
                        {`Total : ${formatNumber(
                          payload.reduce((result, item) => result + parseFloat(item.value as string), 0),
                          displayDecimalPlaces
                        )}${unit}`}
                      </Typography>
                    )}
                  </Box>
                );
              }}
              contentStyle={{
                borderRadius: '10px',
                padding: '15px 20px',
                backgroundColor: colors.solidHoverBackground,
                border: 'none',
              }}
            />
            {!hideLegend && <Legend verticalAlign="top" wrapperStyle={{ paddingBottom: '20px' }} align="center" />}

            {dataKeys?.map((key, index) => (
              <Bar
                barSize={40}
                key={index}
                dataKey={key}
                fill={chartColors[key]}
                stackId={stacked ? 'stack-id' : undefined}
                unit={unit}
              >
                {data?.map((item, index) => (
                  <Cell key={`cell_${index}`} fill={getColor(item, key)}>
                    <LabelList dataKey={key} content={<CustomBarLabel />} />
                  </Cell>
                ))}
              </Bar>
            ))}
            {referenceLine && (
              <ReferenceLine
                y={referenceLine.yValue}
                stroke={referenceLine.stroke ?? '#81ca9c'}
                label={{ value: referenceLine.label, position: referenceLine.position ?? 'left' }}
                strokeDasharray="4 4"
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default DashboardBarChart;
