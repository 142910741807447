/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from '@mui/material';
import { CustomContainer, FacilityLocationFilter, RegularButton } from 'components';
import { DashboardWidget, getChartData } from './charts/chartDataProcessor';
import { Fragment, useContext, useEffect, useMemo } from 'react';
import { getAllReportEntries, getReportEntriesByReportID } from 'api/report-entry';

import { AssessmentBatchModel } from 'model/Entities';
import CustomAutoCompleteSelect from 'components/CustomAutoComplete';
import LoadingScreen from 'screens/Helper/loading';
import { UserContext } from 'context/user.context';
import { getAllReports } from 'api/report';
import { getReportEntryDashboardData } from 'api/facility-report-entry';
import { useState } from 'react';

const ReportsDashboard = () => {
  const { user } = useContext(UserContext);

  const [responses, setResponses] = useState<any[]>([]);
  const [reports, setReports] = useState<any[]>([]);
  const [reportEntries, setReportEntries] = useState<any[]>([]);
  const [selectedReportEntry, setSelectedReportEntry] = useState(0);
  const [selectedReport, setSelectedReport] = useState(0);
  const [widgets, setWidgets] = useState<DashboardWidget[]>([]);
  const [loading, setLoading] = useState(false);
  const [locationFilters, setLocationFilters] = useState<any>({});

  useEffect(() => {
    if (reportEntries.length) {
      if (localStorage.getItem('selectedReportEntry')) {
        const entry_id = localStorage.getItem('selectedReportEntry') as string;
        setSelectedReportEntry(parseInt(entry_id));
      } else {
        setSelectedReportEntry(reportEntries[0].id);
      }
    }
  }, [reportEntries]);

  useEffect(() => {
    if (reports.length) {
      if (localStorage.getItem('selectedReport')) {
        const report_id = localStorage.getItem('selectedReport') as string;
        setSelectedReport(parseInt(report_id));
      } else {
        setSelectedReport(reports[0].id);
      }
    }
  }, [reports]);

  const getResponses = async (selectedReportEntry: AssessmentBatchModel) => {
    try {
      setLoading(true);
      const res = await getReportEntryDashboardData(selectedReportEntry.id!);
      setResponses(res.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedReportEntry) {
      const selected = reportEntries.find((batch) => batch.id === selectedReportEntry);
      if (selected) {
        const template = user.dashboard_templates.find(
          (template) => template.dashboard_code === selected.report.dashboard_code
        );
        if (template) {
          setWidgets(template.template);
          getResponses(selected);
          return;
        }
      }
    }
    setWidgets([]);
    setResponses([]);
    setLoading(false);
  }, [user, selectedReportEntry]);

  useEffect(() => {
    const fetchReportEntries = async () => {
      try {
        const query = { length: 100000, status: ['active', 'closed'] };
        const response = selectedReport
          ? await getReportEntriesByReportID(selectedReport, query)
          : await getAllReportEntries(query);
        if (response && response.data && response.data.rows) {
          setReportEntries(response.data.rows);
        }
      } catch (error) {
        console.error('Failed to fetch report entries:', error);
      } finally {
        // setLoading(false);
      }
    };
    fetchReportEntries();
  }, [selectedReport]);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        setLoading(true);
        const query = { length: 100000 };
        const response = await getAllReports(query);
        if (response && response.data && response.data.rows) {
          setReports(response.data.rows);
        }
      } catch (error) {
        console.error('Failed to reports:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  const handleReportChange = (value: any) => {
    if (value) {
      const selectedValue = value.value;
      setSelectedReport(selectedValue);
      localStorage.setItem('selectedReport', selectedValue);
    } else {
      setSelectedReport(0);
    }
    setSelectedReportEntry(0);
    localStorage.setItem('selectedReportEntry', '0');
  };

  const handleReportEntryChange = (value: any) => {
    if (value) {
      const selectedValue = value.value;
      setSelectedReportEntry(selectedValue);
      localStorage.setItem('selectedReportEntry', selectedValue);
    } else {
      setSelectedReportEntry(0);
    }
  };

  const chart = useMemo(() => {
    return (
      <>
        {widgets.map((chart, index) => {
          return <Fragment key={index}>{getChartData(responses, chart, locationFilters)}</Fragment>;
        })}
      </>
    );
  }, [widgets, responses, locationFilters]);

  const clearFilter = () => {
    for (let key in locationFilters) {
      locationFilters[key] = '';
    }
    setLocationFilters({ ...locationFilters });
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box display="flex" flexDirection="column" gap="20px">
      <CustomContainer>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" gap="10px" width="100%" alignItems="center">
            <FacilityLocationFilter
              handleChangeFilter={(data) => setLocationFilters({ ...locationFilters, ...data })}
              otherFilters={locationFilters}
              showFacilityDropdown
              hideFacilityType
            />
            <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
          </Box>
          <Box display="flex" gap="10px" alignItems="center" justifyContent="flex-end" width="100%">
            <Box width="200px">
              <CustomAutoCompleteSelect
                label="Report"
                fieldName="report"
                value={selectedReport}
                handleChangeCallback={(value) => handleReportChange(value)}
                options={reports.map((report) => ({
                  key: report.name,
                  value: report.id,
                }))}
              />
            </Box>
            <Box width="200px">
              <CustomAutoCompleteSelect
                label="Report Entry"
                fieldName="report_entry"
                value={selectedReportEntry}
                handleChangeCallback={(value) => handleReportEntryChange(value)}
                options={reportEntries.map((entry) => ({
                  key: entry.month,
                  value: entry.id,
                }))}
              />
            </Box>
          </Box>
        </Box>
      </CustomContainer>
      {selectedReportEntry ? (
        <>
          {widgets.length ? (
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="180px" gap="20px">
              {chart}
            </Box>
          ) : (
            <CustomContainer>
              <Typography variant="h5" fontWeight="bold">
                The selected batch has no dashboard.
              </Typography>
            </CustomContainer>
          )}
        </>
      ) : (
        <CustomContainer>
          <Typography variant="h5" fontWeight="bold">
            Please select a batch
          </Typography>
        </CustomContainer>
      )}
    </Box>
  );
};

export default ReportsDashboard;
