import { DashboardWidget } from 'screens/Dashboard/charts/chartDataProcessor';

const COLORS = {
  GREEN: '#81ca9c',
  YELLOW: '#ffc658',
  ORANGE: '#f6914f',
  RED: '#f6665f',
  BLUE: '#54BAD1',
  PURPLE: '#8784d8'
};

export const CARAGADashboard1: DashboardWidget[] = [
  {
    type: 'pie',
    title: 'Facilities Assessed',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 3 },
    xAxisDataKeys: [{ key: 'Facility Type' }],
    yAxisDataKeys: [
      { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
      { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
      { key: 'Infirmary', chartColor: COLORS.BLUE },
      { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
    ],
  },
  {
    type: 'bar',
    title: 'Facilities Assessed per Municipality',
    label: { xAxisLabel: 'Municipality', yAxisLabel: 'Count' },
    containerDimnesion: { colSpan: 9 },
    xAxisDataKeys: [{ key: 'Municipality' }],
    useXAxisDataKeyResponseAsXAxis: true,
    yAxisDataKeys: [
      { key: 'Count',  chartColor: COLORS.BLUE }, 
    ],
    yAxisBreakdown: {
      breakdownKey: 'Facility Type',
      yAxisBreakdownKeys: [
        { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
        { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
        { key: 'Infirmary', chartColor: COLORS.BLUE },
        { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
      ],
    },
  },
  {
    type: 'bar',
    title: 'Outcome Indicators for Mortality',
    label: { xAxisLabel: 'Mortality', yAxisLabel: 'No. of Deaths' },
    aggregation: 'sum',
    xAxisDataKeys: [
      { key: '147', displayName: 'Maternal Deaths' },
      { key: '149', displayName: 'Neonatal Deaths' },
      { key: '151', displayName: 'Infant Deaths (< 1 year of age)' },
      { key: '153', displayName: 'Child Deaths' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
  },
  {
    type: 'bar',
    title: 'Outcome Indicators for Nutrition',
    label: { xAxisLabel: 'Nutrition', yAxisLabel: 'No. of Malnourished Children' },
    aggregation: 'sum',
    xAxisDataKeys: [
      { key: '154', displayName: '0-59 Months Wasting' },
      { key: '155', displayName: '0-59 Months SAM' },
      { key: '156', displayName: '0-59 Months Stunting' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.GREEN }],
  },
  {
    type: 'bar',
    title: 'Service Availability',
    label: { xAxisLabel: 'Services', yAxisLabel: '% of Facilities' },
    containerDimnesion: { colSpan: 12 },
    displayAsPercentage: true,
    hideLegend: true,
    xAxisDataKeys: [
      { key: '012A', displayName: "Health Education" },
      { key: 'Maternal Care' },
      { key: 'Newborn Care' },
      { key: 'Child Care' },
      { key: 'Adolescent Health Services' },
    ],
    yAxisDataKeys: [{ key: 'Yes', chartColor: COLORS.PURPLE }],
    dataPreprocessor: (data) => {
      
      const maternalCareYesFields = ['012D', '012E', '012G', '012U']
      const newbornCareYesFields = ['012H', '012I', '012J', '012K', '012L']
      const childCareYesFields = ['012L', '012M', '012N', '012O', '012P', '012Q', '012R']
      const adolescentHealthYesFields = ['012T', '012R', '012S', '012U'];

      const getValue = (row: any, yesFields: string[]) => {
        let totalIndicators = yesFields.length;
        let count = yesFields.reduce((result, field) => {
          return result + (row[field] === 'No' ? 0 : 1);
        }, 0);
       
        // 100% Yes or Available
        return count === totalIndicators ? 'Yes' : 'No';
      };

      data.forEach((row) => {
        row['Maternal Care'] = getValue(row, maternalCareYesFields);
        row['Newborn Care'] = getValue(row, newbornCareYesFields);
        row['Child Care'] = getValue(row, childCareYesFields);
        row['Adolescent Health Services'] = getValue(row, adolescentHealthYesFields);
      });
      return data;
    },
  },
  {
    type: 'table',
    title: 'Health Workforce',
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'sum',
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: 'Doctors', type: 'number' },
      { key: 'Nurses', type: 'number' },
      { key: 'Midwives', type: 'number' },
      { key: 'Dentists', type: 'number' },
      { key: 'Pharmacists', type: 'number' },
      { key: 'BHWs', type: 'number' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'Doctors',
        'Nurses',
        'Midwives',
        'Pharmacists',
        'Dentists',
        'BHWs',
      ],
      sortBy: 'Facility Name',
    },
    dataPreprocessor: (data) => {
      const doctorSourceFields = ['099A-1', '099A-2', '099A-3', '099A-4', '099A-5'];
      const nursesSourceFields = ['100A-1', '100A-2', '100A-3', '100A-4'];
      const midwivesSourceFields = ['099B-1', '099B-2', '099B-3', '099B-4', '099B-5'];
      const dentistsSourceFields = ['100C-1', '100C-2', '100C-3', '100C-4'];
      const pharmacistsSourceFields = ['100B-1', '100B-2', '100B-3', '100B-4'];
      const bhwSourceFields = ['102A-5'];

      const counter = (row: any, sourceFields: string[]) => {
        return sourceFields.reduce((result, field) => {
          const response = row[field];
          return result + (isNaN(parseFloat(response)) ? 0 : parseFloat(response));
        }, 0);
      };

      data.forEach((row) => {
        row['Doctors'] = counter(row, doctorSourceFields);
        row['Nurses'] = counter(row, nursesSourceFields);
        row['Midwives'] = counter(row, midwivesSourceFields);
        row['Dentists'] = counter(row, dentistsSourceFields);
        row['Pharmacists'] = counter(row, pharmacistsSourceFields);
        row['BHWs'] = counter(row, bhwSourceFields);

      });
      return data;
    },
  },
  {
    type: 'pie',
    title: 'Presence of EMR in RHU',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 4 },
    facilityTypeFilter: ['Rural Health Unit'],
    xAxisDataKeys: [{ key: '128' }],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.GREEN },
      { key: 'No', chartColor: COLORS.ORANGE },
    ],
  },
  {
    type: 'pie',
    title: 'Utilization of EMR for household profiling',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 4 },
    facilityTypeFilter: ['Rural Health Unit'],
    xAxisDataKeys: [{ key: '133A' }],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.GREEN },
      { key: 'No', chartColor: COLORS.ORANGE },
    ],
  },
  {
    type: 'pie',
    title: 'Submission of FHSIS report thru EMR',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 4 },
    facilityTypeFilter: ['Rural Health Unit'],
    xAxisDataKeys: [{ key: '135A' }],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.GREEN },
      { key: 'No', chartColor: COLORS.ORANGE },
    ],
  },
  {
    type: 'bar',
    title: 'PhilHealth Claims',
    description: 'Is claiming of PhilHealth packages available within the facility?',
    label: { xAxisLabel: 'Services', yAxisLabel: '% of Facilities' },
    containerDimnesion: { colSpan: 12 },
    facilityTypeFilter: ['Rural Health Unit'],
    stacked: true,
    xAxisDataKeys: [
      { key: '134A-1', displayName: "E-Konsulta" },
      { key: '134B-1', displayName: "Maternal Care Package (MCP)" },
      { key: '134C-1', displayName: "TB-DOTS" },
      { key: '134D-1', displayName: "Animal Bite Package" },
      { key: '134E-1', displayName: "Family Planning Claims" },
      { key: '134F-1', displayName: "COVID Isolation Claims" },
    ],
    yAxisDataKeys: [{ key: 'Yes', chartColor: COLORS.GREEN }, { key: 'No', chartColor: COLORS.RED }],
  },
  {
    type: 'bar',
    title: 'PhilHealth Accreditation',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: '% of Facilities with Packages' },
    containerDimnesion: { colSpan: 6 },
    aggregation: 'count',
    displayAsPercentage: true,
    stacked: true,
    xAxisDataKeys: [
      { key: '009A', displayName: 'Konsulta Package' },
      { key: '009B', displayName: 'Maternity Care Package' },
      { key: '009C', displayName: 'Newborn Care Package' },
      { key: '009D', displayName: 'Family Planning Package' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.GREEN },
      { key: 'No', chartColor: COLORS.RED },
    ],
  },
  {
    type: 'bar',
    title: 'Demand Generation',
    label: { xAxisLabel: 'DOH Policies', yAxisLabel: '% of Facilities' },
    containerDimnesion: { colSpan: 6 },
    stacked: true,
    xAxisDataKeys: [
      { key: 'Maternal Care' },
      { key: 'Oral Health' },
      { key: 'Family Planning' },
      { key: 'Newborn, Child, and Adolescent Health' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.GREEN },
      { key: 'No', chartColor: COLORS.RED },
    ],
    dataPreprocessor: (data) => {
      const maternalCareYesFields   = ['015A', '015B', '015C', '015D', '015E', '015F'];
      const oralHealthYesFields     = ['016A', '016B', '016C', '016D', '016E', '016F'];
      const familyPlanningYesFields = ['017A', '017B', '017C', '017D', '017E'];
      const ncaHealthYesFields      = ['018A', '018B'];

      const getValue = (row: any, yesFields: string[], minimumYes?: number) => {
        minimumYes = minimumYes ?? 3;
        let count = yesFields.reduce((result, field) => {
          return result + (row[field] === 'No' ? 0 : 1);
        }, 0);

        return count >= minimumYes ? 'Yes' : 'No';
      };

      data.forEach((row) => {
        row['Maternal Care'] = getValue(row, maternalCareYesFields);
        row['Oral Health'] = getValue(row, oralHealthYesFields);
        row['Family Planning'] = getValue(row, familyPlanningYesFields);
        row['Newborn, Child, and Adolescent Health'] = getValue(row, ncaHealthYesFields, 2);
      });
      return data;
    },
  },
  {
    type: 'table',
    title: 'Health Worker Training - Antenatal Care',
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12, rowSpan: 3 },
    aggregation: 'sum',
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: '105A-6', displayName: 'Interpersonal Communication Skills (ICS)' },
      { key: '105B-6', displayName: 'Community Tracking Tool' },
      { key: '105C-6', displayName: 'Supportive Supervision' },
      { key: '105D-6', displayName: 'Diagnosis and Treatment of Malaria in Pregnancy' },
      { key: '105E-6', displayName: 'Diagnosis and/or Treatment of STI, excluding HIV' },
      { key: '105F-6', displayName: 'HIV Counseling' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'Interpersonal Communication Skills (ICS)',
        'Community Tracking Tool',
        'Supportive Supervision',
        'Diagnosis and Treatment of Malaria in Pregnancy',
        'Diagnosis and/or Treatment of STI, excluding HIV',
        'HIV Counseling',
      ],
      sortBy: 'Facility Name',
    },
  },
  {
    type: 'table',
    title: 'Health Worker Training - Delivery Services',
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12, rowSpan: 3 },
    aggregation: 'sum',
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: '106A-6', displayName: 'Basic Life Support (BLS)' },
      { key: '107A-6', displayName: 'BEmONC' },
      { key: '107B-6', displayName: 'EINC' },
      { key: '107C-6', displayName: 'LMT' },
      { key: '107D-6', displayName: 'Expanded Newborn Screening Training' },
      { key: '107F-6', displayName: 'MDRRS' },
      { key: '107G-6', displayName: 'MNIDRS' },

    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'Basic Life Support (BLS)',
        'BEmONC',
        'EINC',
        'LMT',
        'Expanded Newborn Screening Training',
        'MDRRS',
        'MNIDRS'
      ],
      sortBy: 'Facility Name',
    },
  },
  {
    type: 'table',
    title: 'Health Worker Training - Postnatal and Newborn Care',
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12, rowSpan: 3 },
    aggregation: 'sum',
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: '109A-6', displayName: 'Kangaroo Mother Care for Premature' },
      { key: '109B-6', displayName: 'Modern Methods of Family Planning' },
      { key: '109C-6', displayName: 'Postpartum Intrauterine Device Insertion' },
      { key: '110A-6', displayName: 'Expanded Program on Immunization' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'Kangaroo Mother Care for Premature',
        'Modern Methods of Family Planning',
        'Postpartum Intrauterine Device Insertion',
        'Expanded Program on Immunization'
      ],
      sortBy: 'Facility Name',
    },
  },
  {
    type: 'table',
    title: 'Health Worker Training - Family Planning',
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12, rowSpan: 3 },
    aggregation: 'sum',
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: '111A-6', displayName: 'FPBCT Level 1' },
      { key: '112A-6', displayName: 'Interval IUD insertion and Removal' },
      { key: '112B-6', displayName: 'Post-Partum IUD Insertion and Removal' },
      { key: '112C-6', displayName: 'Progestin-only Subdermal Implant Insertion and Removal' },
      { key: '113A-1', displayName: 'No-Scalpel Vasectomy' },
      { key: '113B-1', displayName: 'Bilateral Tubal Ligation-Mini-Laparotomy' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'FPBCT Level 1',
        'Interval IUD insertion and Removal',
        'Post-Partum IUD Insertion and Removal',
        'Progestin-only Subdermal Implant Insertion and Removal',
        'No-Scalpel Vasectomy',
        'Bilateral Tubal Ligation-Mini-Laparotomy',
      ],
      sortBy: 'Facility Name',
    },
  },
  {
    type: 'table',
    title: 'Health Worker Training - Child Vaccination',
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12, rowSpan: 3 },
    aggregation: 'sum',
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: '114A-6', displayName: 'PIMAM' },
      { key: '115A-6', displayName: 'Growth Monitoring' },
      { key: '116A-6', displayName: 'Integrated Management of Child Illness' },
      { key: '116B-6', displayName: 'Management of TB in Children' },
      { key: '116C-6', displayName: 'MNIYCF' },
      { key: '116D-6', displayName: 'Capacity Enhancement Cours' },
      { key: '116E-6', displayName: 'Expanded Program on Immunization' },
      { key: '116F-6', displayName: 'Cold Chain Management Training' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'PIMAM',
        'Growth Monitoring',
        'Integrated Management of Child Illness',
        'Management of TB in Children',
        'MNIYCF',
        'Capacity Enhancement Cours',
        'Expanded Program on Immunization',
        'Cold Chain Management Training',
      ],
      sortBy: 'Facility Name',
    },
  },
  {
    type: 'table',
    title: 'Health Worker Training - Adolescent Health Services and Other Trainings',
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12, rowSpan: 3 },
    aggregation: 'sum',
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: '117A-6', displayName: 'Adolescent Job Aid (AJA)' },
      { key: '117B-6', displayName: 'Healthy Young Ones' },
      { key: '117C-6', displayName: 'Adolescent Health Care for Primary Health Service Providers' },
      { key: '117D-6', displayName: 'Comprehensive Management on Adolescent Health' },
      { key: '117E-6', displayName: 'Adolescent Health, Education and Practical Training (ADEPT)' },
      { key: '118A-3', displayName: 'SMART Verbal Autopsy (VA)' },
      { key: '118B-3', displayName: 'International Classification of Diseases (ICD)' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'Adolescent Job Aid (AJA)',
        'Healthy Young Ones',
        'Adolescent Health Care for Primary Health Service Providers',
        'Comprehensive Management on Adolescent Health',
        'Adolescent Health, Education and Practical Training (ADEPT)',
        'SMART Verbal Autopsy (VA)',
        'International Classification of Diseases (ICD)',
      ],
      sortBy: 'Facility Name',
    },
  },
];
