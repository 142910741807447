import ActivateReactivateBatch from './AssessmentManagement/ActivateReactivateBatchArticle';
import AddFacilitiesToBatchArticle from './AssessmentManagement/AddFacilitiesToBatchArticle';
import AddFacilitiesToGroupArticle from './FacilityManagement/AddFacilitiesToGroup';
import AddRemoveFacilitiesReportEntryArticle from './ReportingTool/AddRemoveFacilitiesReportEntryArticle';
import AddUpdateValidationWorklfow from './ValidationWorkflow/AddUpdateValidationWorklfow';
import CRUDReportArticle from './ReportingTool/CRUDReportArticle';
import CRUDReportEntryArticle from './ReportingTool/CRUDReportEntryArticle';
import CloseBatchArticle from './AssessmentManagement/CloseBatchArticle';
import CreateAssessmentArticle from './AssessmentManagement/CreateAssessmentArticle';
import CreateDeleteValidationWorkflowArticle from './ValidationWorkflow/CreateDeleteValidationWorkflowArticle';
import CreateFacilityArticle from './FacilityManagement/CreateFacilityArticle';
import CreateFacilityGroupArticle from './FacilityManagement/CreateFacilityGroupArticle';
import CreateFacilityStaffArticle from './UserManagement/CreateFacilityStaffArticle';
import CreateIndicatorArticle from './IndicatorsManagement/CreateIndicatorArticle';
import CreateUserArticle from './UserManagement/CreateUserArticle';
import DeleteAssessmentArticle from './AssessmentManagement/DeleteAssessmentArticle';
import DeleteFacilityArticle from './FacilityManagement/DeleteFacilityArticle';
import DeleteIndicatorArticle from './IndicatorsManagement/DeleteIndicatorArticle';
import DeleteUserArticle from './UserManagement/DeleteUserArticle';
import DuplicateIndicatorArticle from './IndicatorsManagement/DuplicateIndicatorArticle';
import ImportFacilityArticle from './FacilityManagement/ImportFacilityArticle';
import RemoveFacilitiesFromGroupArticle from './FacilityManagement/RemoveFacilitiesFromGroupArticle';
import ReopenBatchArticle from './AssessmentManagement/ReopenBatchArticle';
import RespondReportArticle from './ReportingTool/RespondReportArticle';
import StatusReportEntryArticle from './ReportingTool/StatusReportEntryArticle';
import UpdateAssessmentArticle from './AssessmentManagement/UpdateAssessmentArticle';
import UpdateFacilityArticle from './FacilityManagement/UpdateFacilityArticle';
import UpdateIndicatorArticle from './IndicatorsManagement/UpdateIndicatorArticle';
import UpdateUserArticle from './UserManagement/UpdateUserArticle';
import ViewFacilitiesInGroupArticle from './FacilityManagement/ViewFacilitiesInGroupArticle';
import { isFresaarProject } from 'utils';

export const categories = [
  {
    categoryName: 'User Management',
    categoryCode: 'user-management',
    articles: [
      {
        article_title: 'How to Create User (Except for Facility Staff User)',
        article_code: 'create-user',
        content: <CreateUserArticle />,
        prev: 'null',
        next: 'create-facility-staff-user',
      },
      {
        article_title: 'How to Create Facility Staff User',
        article_code: 'create-facility-staff-user',
        content: <CreateFacilityStaffArticle />,
        prev: 'create-user',
        next: 'update-user',
      },
      {
        article_title: 'How to Update User',
        article_code: 'update-user',
        content: <UpdateUserArticle />,
        prev: 'create-facility-staff-user',
        next: 'delete-user',
      },
      {
        article_title: 'How to Delete User',
        article_code: 'delete-user',
        content: <DeleteUserArticle />,
        prev: 'update-user',
        next: 'null',
      },
    ],
  },
  {
    categoryName: 'Facility Management',
    categoryCode: 'facility-management',
    articles: [
      {
        article_title: 'How to Create Facility',
        article_code: 'create-facility',
        content: <CreateFacilityArticle />,
        prev: 'null',
        next: 'import-facility',
      },
      {
        article_title: 'How to Import Facility',
        article_code: 'import-facility',
        content: <ImportFacilityArticle />,
        prev: 'create-facility',
        next: 'update-facility',
      },
      {
        article_title: 'How to Update Facility',
        article_code: 'update-facility',
        content: <UpdateFacilityArticle />,
        prev: 'import-facility',
        next: 'delete-facility',
      },
      {
        article_title: 'How to Delete Facility',
        article_code: 'delete-facility',
        content: <DeleteFacilityArticle />,
        prev: 'update-facility',
        next: 'create-facility-group',
      },
      {
        article_title: 'How to Create Facility Group',
        article_code: 'create-facility-group',
        content: <CreateFacilityGroupArticle />,
        prev: 'delete-facility',
        next: 'view-facilities-in-group',
      },
      {
        article_title: 'How to View Facilities in Facility Group',
        article_code: 'view-facilities-in-group',
        content: <ViewFacilitiesInGroupArticle />,
        prev: 'create-facility-group',
        next: 'add-facilities-to-group',
      },
      {
        article_title: 'How to Add Facilities to a Group',
        article_code: 'add-facilities-to-group',
        content: <AddFacilitiesToGroupArticle />,
        prev: 'view-facilities-in-group',
        next: 'remove-facilities-from-group',
      },
      {
        article_title: 'How to Remove Facilities from a Group',
        article_code: 'remove-facilities-from-group',
        content: <RemoveFacilitiesFromGroupArticle />,
        prev: 'add-facilities-to-group',
        next: 'null',
      },
    ],
  },
  {
    categoryName: 'Assessment Management',
    categoryCode: 'assessment-management',
    articles: [
      {
        article_title: 'How to Create Batch',
        article_code: 'create-batch',
        content: <CreateAssessmentArticle />,
        prev: 'null',
        next: 'add-facilities-to-batch',
      },
      {
        article_title: 'How to Add facilities to batch',
        article_code: 'add-facilities-to-batch',
        content: <AddFacilitiesToBatchArticle />,
        prev: 'create-batch',
        next: 'update-batch',
      },
      {
        article_title: 'How to Update Batch',
        article_code: 'update-batch',
        content: <UpdateAssessmentArticle />,
        prev: 'add-facilities-to-batch',
        next: 'delete-batch',
      },
      {
        article_title: 'How to Delete Batch',
        article_code: 'delete-batch',
        content: <DeleteAssessmentArticle />,
        prev: 'update-batch',
        next: 'activate-reactivate-batch',
      },
      {
        article_title: 'How to Activate/Reactivate Batch',
        article_code: 'activate-reactivate-batch',
        content: <ActivateReactivateBatch />,
        prev: 'delete-batch',
        next: 'close-batch',
      },
      {
        article_title: 'How to Close Batch',
        article_code: 'close-batch',
        content: <CloseBatchArticle />,
        prev: 'activate-reactivate-batch',
        next: 'reopen-batch',
      },
      {
        article_title: 'How to Reopen Batch',
        article_code: 'reopen-batch',
        content: <ReopenBatchArticle />,
        prev: 'close-batch',
        next: 'null',
      },
    ],
  },
  {
    categoryName: 'Validation Workflow',
    categoryCode: 'validation-workflow',
    articles: [
      {
        article_title: 'How to Create and Delete Validation Workflow',
        article_code: 'crud-validation-workflow',
        content: <CreateDeleteValidationWorkflowArticle />,
        prev: null,
        next: 'add-steps-validation-workflow',
      },
      {
        article_title: 'How to Add and Update Steps in Validation Workflow',
        article_code: 'add-steps-validation-workflow',
        content: <AddUpdateValidationWorklfow />,
        prev: 'crud-validation-workflow',
        next: null,
      },
    ],
  },
  {
    categoryName: 'Indicators',
    categoryCode: 'indicators',
    articles: [
      {
        article_title: 'How to Update Indicators',
        article_code: 'update-indicators',
        content: <UpdateIndicatorArticle />,
        prev: null,
        next: 'create-indicators',
      },
      {
        article_title: 'How to Create Indicators',
        article_code: 'create-indicators',
        content: <CreateIndicatorArticle />,
        prev: 'update-indicators',
        next: 'duplicate-indicators',
      },
      {
        article_title: 'How to Duplicate Indicators',
        article_code: 'duplicate-indicators',
        content: <DuplicateIndicatorArticle />,
        prev: 'create-indicators',
        next: 'delete-indicators',
      },
      {
        article_title: 'How to Delete Indicators',
        article_code: 'delete-indicators',
        content: <DeleteIndicatorArticle />,
        prev: 'duplicate-indicators',
        next: null,
      },
    ],
  },
  {
    categoryName: 'Reporting Tool',
    hidden: !isFresaarProject(),
    categoryCode: 'reporting-tool',
    articles: [
      {
        article_title: 'How to Create, Update, and Delete Report',
        article_code: 'report',
        content: <CRUDReportArticle />,
        prev: null,
        next: 'report-entry',
      },
      {
        article_title: 'How to Create, Update, Delete, and Duplicate Report Entry',
        article_code: 'report-entry',
        content: <CRUDReportEntryArticle />,
        prev: 'report',
        next: 'report-entry-add-remove',
      },
      {
        article_title: 'How to Add/Remove Facilities from Report Entry',
        article_code: 'report-entry-add-remove',
        content: <AddRemoveFacilitiesReportEntryArticle />,
        prev: 'report-entry',
        next: 'report-entry-active',
      },
      {
        article_title: 'How to Activate/Reopen/Close Report Entry',
        article_code: 'report-entry-active',
        content: <StatusReportEntryArticle />,
        prev: 'report-entry-add-remove',
        next: 'respond-report',
      },
      {
        article_title: 'How to Respond and Submit Reports (Facility Staff)',
        article_code: 'respond-report',
        content: <RespondReportArticle />,
        prev: 'report-entry-active',
        next: null,
      },
    ],
  },
];
