/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'model/interface';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomDrawer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  PrimaryButton,
  ProtectedComponent,
  RowAction,
} from 'components';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { changeNullToBlank, getStatusChip, tranformFormErrors } from 'utils';
import {
  createAssessmentBatch,
  deleteAssessmentBatch,
  getAssessmentBatches,
  updateAssessmentBatch,
  updateAssessmentBatchStatus,
} from 'api/assessment-batch';
import { useContext, useEffect, useState } from 'react';

import { AssessmentBatchModel } from 'model/Entities';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTable from 'components/CustomTable';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { DropdownOptionProps } from 'components/Dropdown';
import FacilityAssessment from './FacilityAssessment';
import { FormikHelpers } from 'formik';
import Header from 'components/Header';
import { UserContext } from 'context/user.context';
import { create_assessment_batch_schema } from 'model/schema';
import { getAssessmentIndicators } from 'api/assessment-indicator';
import { tokens } from 'context/theme.context';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material';

//MUI ICONS FOR ACTIONS

type BatchAssessmentInput = {
  batch_name: string;
  year: string;
  indicator_id: string;
  dashboard_code: string;
};

interface BatchAssessmentFieldInput extends FieldInput {
  field_name: keyof BatchAssessmentInput;
}

export interface HealthIndicatorDropdown {
  value: any;
  key: string;
}

const initialValues: BatchAssessmentInput = {
  batch_name: '',
  year: '',
  indicator_id: '',
  dashboard_code: '',
};

const AssessmentManagement = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);

  const [rows, setRows] = useState<AssessmentBatchModel[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [schema, setSchema] = useState<any>(create_assessment_batch_schema);
  const [modalHeader, setModalHeader] = useState('Create Batch');
  const [batchInitialValues, setBatchInitialValues] = useState<BatchAssessmentInput>(initialValues);
  const [clearSelected, setClearSelected] = useState(0);

  const delete_batch_confirmation = 'Are you sure want to delete this batch?';
  const close_batch_confirmation = 'Are you sure want to close this batch?';

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [confirmationMesage, seConfirmationMesage] = useState(delete_batch_confirmation);
  const [selectedRow, setSelectedRow] = useState<number>();
  const [selectedBatchID, setSelectedBatchID] = useState<number>(0); // for edit/delete
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [healthIndicators, setHealthIndicators] = useState<HealthIndicatorDropdown[]>([]);

  const getHealthIndicators = async () => {
    try {
      const { data } = await getAssessmentIndicators({ length: 10000 });
      if (data.rows.length) {
        setHealthIndicators(
          data.rows.map((ind: any) => {
            return { value: ind?.id, key: ind?.name };
          })
        );
      } else {
        setHealthIndicators([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateStatus = (batch_id: number, status: any) => {
    updateAssessmentBatchStatus(batch_id, status)
      .then((res) => {
        enqueueSnackbar('Status sucessfully updated!', {
          variant: 'success',
        });
        refreshTable();
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: 'error',
        });
      });
  };

  const closeAssessmentBatch = () => {
    setSelectedRow(undefined);
    setClearSelected((prev: number) => prev + 1);
  };

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'batch_name',
      sortable: false,
      headerName: 'Batch Name',
      flex: 3,
      renderCell: (params) => <CustomGridCell>{params.row.batch_name}</CustomGridCell>,
    },
    {
      field: 'indicator_id',
      sortable: false,
      headerName: 'Health Indicators',
      flex: 1.5,
      renderCell: (params) => <CustomGridCell>{params.row.indicator?.name}</CustomGridCell>,
    },
    {
      field: 'year',
      sortable: false,
      headerName: 'Year',
      flex: 1,
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{getStatusChip(params.row.status, theme, colors)}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];
  const [batchColumns, setBatchColumns] = useState<GridColDef[]>(columns);

  // Form Fields
  const formFields: BatchAssessmentFieldInput[] = [
    {
      field_name: 'batch_name',
      display_name: 'Batch Name',
      type: 'text',
      span: 4,
    },
    {
      field_name: 'year',
      display_name: 'Year',
      type: 'year',
      span: 4,
    },
    {
      field_name: 'indicator_id',
      display_name: 'Health Indicators',
      type: 'autocomplete',
      helperText: 'Just select one indicator. You can add more indicators later.',
      span: 4,
      options: healthIndicators?.map((h) => {
        return { key: h.key, value: h.value };
      }),
      hidden: modalHeader === 'Update Batch',
    },
    {
      field_name: 'dashboard_code',
      display_name: 'Dashboard Template (optional)',
      type: 'autocomplete',
      span: 4,
      options: user?.dashboard_templates?.map((h) => {
        return { key: h.dashboard_name, value: h.dashboard_code };
      }),
    },
  ];

  // Actions
  const updateBatch = (data: any) => {
    data = changeNullToBlank(data);
    showModal(true, data);
  };

  const deleteBatch = (data: any) => {
    setSelectedBatchID(data.id);
    seConfirmationMesage(delete_batch_confirmation);
    setOpenDeleteConfirmation(true);
  };

  const hideOption = (data: any, option: string) => {
    if (option === 'Update') return data.status !== 'open';
    if (option === 'Delete') return data.status !== 'open';
    if (option === 'Activate') return data.status !== 'open';
    if (option === 'Reopen') return data.status !== 'active';
    if (option === 'Close') return data.status !== 'active';
    if (option === 'Reactivate') return data.status !== 'closed';

    return true;
  };

  const actions: DropdownOptionProps[] = [
    {
      label: 'Update',
      action: updateBatch,
      startIcon: <DriveFileRenameOutlineIcon />,
    },
    {
      label: 'Delete',
      action: deleteBatch,
      startIcon: <DeleteOutlinedIcon />,
      hideCallback: (data: any) => hideOption(data, 'Delete'),
    },
    // {
    //   label: 'Activate',
    //   action: (data: any) => updateStatus(data, 'active'),
    //   hideCallback: (data: any) => hideOption(data, 'Activate'),
    //   startIcon: <ToggleOnIcon />,
    // },
    // {
    //   label: 'Reopen',
    //   action: (data: any) => updateStatus(data, 'open'),
    //   hideCallback: (data: any) => hideOption(data, 'Reopen'),
    //   startIcon: <LockOpenIcon />,
    // },
    // {
    //   label: 'Close',
    //   action: closeBatch,
    //   hideCallback: (data: any) => hideOption(data, 'Close'),
    //   startIcon: <CloseIcon />,
    // },
    // {
    //   label: 'Reactivate',
    //   action: (data: any) => updateStatus(data, 'active'),
    //   hideCallback: (data: any) => hideOption(data, 'Reactivate'),
    //   startIcon: <CloseIcon />,
    // },
  ];

  // Functions
  const getBatches = (query?: ApiQuery) => {
    setLoading(true);
    getAssessmentBatches({
      page: 1,
      length: 10,
      ...query,
    })
      .then((res) => {
        setRows(res.data.rows);
        setRowCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true); //when button submit is clicked turns on the loading animation
    if (data.id) {
      updateAssessmentBatch(data.id, data)
        .then((res: any) => {
          setButtonLoading(false); //Whenever Update update user is a success, set the loading to false
          successFormSubmit(true, formikHelpers);
        })
        .catch((error) => {
          setButtonLoading(false); //whwnever there is an errpr, set the loading to false
          formikHelpers.setErrors(tranformFormErrors(error.response.data));
        });
      return;
    }

    createAssessmentBatch(data)
      .then((res: any) => {
        setButtonLoading(false);
        successFormSubmit(false, formikHelpers);
      })
      .catch((error) => {
        setButtonLoading(false);
        formikHelpers.setErrors(tranformFormErrors(error.response.data));
      });
  };

  const handleDelete = (id: number) => {
    deleteAssessmentBatch(id)
      .then((res: any) => {
        enqueueSnackbar('Batch successfully deleted!', {
          variant: 'success',
        });
        getBatches();
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: 'error',
        });
      });
    return;
  };

  const handleSelectBatch = (selected: GridSelectionModel) => {
    if (selected.length && selectedRow !== selected[0]) {
      setSelectedRow(selected[0] as number);
    } else {
      setSelectedRow(undefined);
    }
  };

  const successFormSubmit = (from_edit: boolean, { resetForm, setErrors }: FormikHelpers<any>) => {
    enqueueSnackbar(`Batch successfully ${from_edit ? 'updated' : 'created'}!`, {
      variant: 'success',
    });

    if (!from_edit) {
      resetForm();
    }

    setOpenModal(false);
    getBatches();
  };

  const showModal = (from_edit?: boolean, data?: BatchAssessmentInput) => {
    if (from_edit && data) {
      setBatchInitialValues(data);
      setModalHeader('Update Batch');
    } else {
      setModalHeader('Create Batch');
      setBatchInitialValues(initialValues);
    }
    setSchema(create_assessment_batch_schema);
    setOpenModal(true);
  };

  useEffect(() => {
    setHealthIndicators([]);
    getHealthIndicators();

    setBreadcrumb([{ label: 'Assessments' }]);
  }, []);

  useEffect(() => {
    if (!user.authorities?.includes('MANAGE_FRESAA_BATCHES')) {
      setBatchColumns(columns.filter((col) => col.field !== 'action'));
    }
  }, [user]);

  return (
    <>
      <CustomDrawer
        gridTemplateColumns="30% 1fr"
        showChild={!!selectedRow}
        parentComponent={
          <CustomContainer>
            <Header
              title="Assessment Batch"
              subtitle={selectedRow ? '' : 'Click a batch to display Facility Assessments'}
              mb="0"
            />

            <CustomTable
              searchKeys="Batch Name"
              columns={
                selectedRow
                  ? batchColumns.filter((col) => ['batch_name', 'status', 'action'].includes(col.field))
                  : batchColumns
              }
              rows={rows}
              rowCount={rowCount}
              loading={loading}
              getData={getBatches}
              clearSelected={clearSelected}
              isRowSelectable
              handleSelectRow={handleSelectBatch}
              forceRefresh={refresh}
              headerComponent={
                <ProtectedComponent requiredAuth={['MANAGE_FRESAA_BATCHES']}>
                  <PrimaryButton onClick={() => showModal()} label={'Create Batch'} />
                </ProtectedComponent>
              }
            />
          </CustomContainer>
        }
        childComponent={
          <FacilityAssessment
            refreshAssessment={getBatches}
            selectedBatch={selectedRow}
            batch={rows.find((batch) => batch.id === selectedRow)}
            handleClose={closeAssessmentBatch}
          />
        }
      />

      {/* Batch */}
      <CustomModal header={modalHeader} open={openModal} setOpen={setOpenModal} width={500}>
        <CustomForm
          initialValues={batchInitialValues}
          onSubmit={handleSubmit}
          fields={formFields}
          schema={schema}
          loading={buttonLoading}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message={confirmationMesage}
        onConfirm={() => {
          if (confirmationMesage === delete_batch_confirmation) handleDelete(selectedBatchID);
          else if (confirmationMesage === close_batch_confirmation) handleUpdateStatus(selectedBatchID, 'closed');
        }}
      />
    </>
  );
};

export default AssessmentManagement;
