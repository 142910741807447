import { pcf_equipment, pcf_facility_info, pcf_items } from 'data/Aklan/dashboard/AklanProvinceWide_V1/pcf_Aklan';
import { pcf_equipment_sample, pcf_facility_info_sample, pcf_items_sample } from 'data/Aklan/dashboard/AklanPilot/pcf_Sample';

import { AklanDashboard1 } from 'data/Aklan/dashboard/AklanProvinceWide_V1/chartConfiguration_Aklan';
import { AklanSampleDashboard } from 'data/Aklan/dashboard/AklanPilot/chartConfiguration_Sample';
import { DashboardTemplate } from '.';

export const aklan_dashboards: DashboardTemplate[] = [
  {
    dashboard_code: 'aklan_dashboard_1',
    dashboard_name: 'Aklan FRESAA Dashboard',
    template: AklanDashboard1,
    pcf: [pcf_facility_info, pcf_items, pcf_equipment],
  },
  {
    dashboard_code: 'aklan_dashboard_2',
    dashboard_name: 'Aklan FRESAA Dashboard (Sample)',
    template: AklanSampleDashboard,
    pcf: [pcf_facility_info_sample, pcf_items_sample, pcf_equipment_sample],
  },
];
