import { DashboardWidget } from 'screens/Dashboard/charts/chartDataProcessor';

const COLORS = {
  GREEN: '#81ca9c',
  YELLOW: '#ffc658',
  ORANGE: '#f6914f',
  RED: '#f6665f',
  BLUE: '#54BAD1',
  PURPLE: '#8784d8'
};

export const EasternVisayasDashboard1: DashboardWidget[] = [
  {
    type: 'pie',
    indicatorName: 'EV WHO FRESAAR Indicators',
    title: 'Facilities Assessed',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 3 },
    xAxisDataKeys: [{ key: 'Facility Type' }],
    yAxisDataKeys: [
      { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
      { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
      { key: 'Infirmary', chartColor: COLORS.BLUE },
      { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
    ],
  },
  {
    type: 'bar',
    indicatorName: 'EV WHO FRESAAR Indicators',
    title: 'Facilities Assessed per Municipality',
    label: { xAxisLabel: 'Municipality', yAxisLabel: 'Count' },
    containerDimnesion: { colSpan: 9 },
    xAxisDataKeys: [{ key: 'Municipality' }],
    useXAxisDataKeyResponseAsXAxis: true,
    yAxisDataKeys: [
      { key: 'Count',  chartColor: COLORS.BLUE }, 
    ],
    yAxisBreakdown: {
      breakdownKey: 'Facility Type',
      yAxisBreakdownKeys: [
        { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
        { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
        { key: 'Infirmary', chartColor: COLORS.BLUE },
        { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
      ],
    },
  },
  {
    type: 'bar',
    indicatorName: 'EV WHO FRESAAR Indicators',
    title: 'PhilHealth Accreditation',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities' },
    containerDimnesion: { colSpan: 8 },
    aggregation: 'count',
    xAxisDataKeys: [
      { key: '012A', displayName: 'Maternity Care Package' }, //TODO: 009A
      { key: '012B', displayName: 'eKonsulta Package' },
      { key: '012C', displayName: 'TB-DOTS' },
      { key: '012D', displayName: 'Animal Bite Treatment Center' },
      { key: '012E', displayName: 'Mental Health Package' },
      { key: '012F', displayName: 'OHAT' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.PURPLE },
    ],
  },
  {
    type: 'pie',
    indicatorName: 'EV WHO FRESAAR Indicators',
    title: 'AFHF Level 1 Certified Facilities',
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 4 },
    xAxisDataKeys: [{ key: '010' }],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.GREEN },
    ],
    yAxisBreakdown: {
      breakdownKey: 'Facility Type',
      yAxisBreakdownKeys: [
        { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
        { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
        { key: 'Infirmary', chartColor: COLORS.BLUE },
        { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
      ],
    },
  },
  {
    type: 'bar',
    indicatorName: 'EV WHO FRESAAR Indicators',
    title: 'AFHF Level 1 and Level 2 Certified Facilities',
    label: { xAxisLabel: 'AFHF Level', yAxisLabel: 'No. of Facilities' },
    containerDimnesion: { colSpan: 4 },
    aggregation: 'count',
    xAxisDataKeys: [
      { key: '010', displayName: 'AFHF Level 1' },
      { key: '011', displayName: 'AFHF Level 2' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.BLUE },
    ],
  },
  {
    type: 'bar',
    indicatorName: 'EV WHO FRESAAR Indicators',
    title: 'Deficiencies in Applying for AFHF Level 2',
    label: { xAxisLabel: 'Common Reasons', yAxisLabel: '% of Facilities' },
    containerDimnesion: { colSpan: 8 },
    aggregation: 'count',
    displayAsPercentage: true,
    xAxisDataKeys: [
      { key: '012B', displayName: 'AJA / ADEPT' }, //TODO: 015B-F
      { key: '012C', displayName: 'FPCBT 1' },
      { key: '012D', displayName: 'FPCBT 2 - IIUD' },
      { key: '012E', displayName: 'FPCBT 2 - PPIUD' },
      { key: '012F', displayName: 'FPCBT 2 - PSI' },
    ],
    yAxisDataKeys: [
      { key: 'No', displayName: 'Facility deficiency for AFHF Level 2 Accreditation', chartColor: COLORS.ORANGE },
    ],
  },
];
