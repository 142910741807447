/* eslint-disable react-hooks/exhaustive-deps */

import { CustomSelectField, Header } from 'components';
import { useContext, useEffect, useMemo } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import { UserContext } from 'context/user.context';
import { getChartData } from './charts/chartDataProcessor';
import healthData from '../../data/sample_data_aklan.json';
import { useState } from 'react';

const DashboardTemplates = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);

  const [selectedDashboard, setSelectedDashboard] = useState<any[]>([]);
  const [selectedDashboardCode, setSelectedDashboardCode] = useState('');
  const [dashboards, setDashboards] = useState<any[]>([]);

  const handleChange = (e: any) => {
    const selectedValue = e.target.value;
    setSelectedDashboardCode(selectedValue);
    const selected = dashboards.find((item) => item.dashboard_code === selectedValue);
    setSelectedDashboard(selected.template);
  };

  useEffect(() => {
    if (user.dashboard_templates) {
      setDashboards(user.dashboard_templates);
    }
  }, [user]);

  useEffect(() => {
    if (dashboards.length) {
      setSelectedDashboard(dashboards[0].template);
      setSelectedDashboardCode(dashboards[0].dashboard_code);
    }
  }, [dashboards]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Dashboard Templates' }]);
  }, []);

  const chart = useMemo(() => {
    return (
      <>
        {selectedDashboard.map((chart) => {
          return getChartData(healthData, chart);
        })}
      </>
    );
  }, [selectedDashboard]);

  return (
    <Box>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Dashboard Templates" />
        <Box display="flex" gap="20px" alignItems="flex-end">
          <Box width="250px">
            <CustomSelectField
              label="Templates"
              fieldName="assessment_batch"
              value={selectedDashboardCode}
              handleChange={handleChange}
              options={dashboards.map((dashboard) => ({
                key: dashboard.dashboard_name,
                value: dashboard.dashboard_code,
              }))}
            />
          </Box>
        </Box>
      </Box>
      {/* GRID & CHARTS */}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="180px" gap="20px">
        {/* ROW 1 */}
        {chart}
      </Box>
    </Box>
  );
};

export default DashboardTemplates;
