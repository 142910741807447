import { ApiQuery } from 'model/interface';
import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'facility-report-entry';

interface requestFormat {
  facilities: number[];
}

export const getFacilityReportEntry = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getFacilityReportEntriesByReportEntryId = (id: number, id_source: string, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/list/${id_source}/${id}`, { params: query });
};

export const getReportEntryDetailsByFacility = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/facility/${facility_id}`, { params: query });
};

export const getActiveReportEntryFacilityEntries = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/active-report-entry/all`, { params: query });
};

export const getFacilitiesNotInEntry = (report_entry_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/available/${report_entry_id}`, { params: query });
};

export const getApprovedFacilityReportAssessments = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/approved/all`, { params: query });
};

export const createReportEntry = (report_entry_id: number, facilities: requestFormat) => {
  return axios.post(`${baseURL}/${path}/${report_entry_id}`, facilities);
};

export const createReportEntryToAllAvailable = (report_entry_id: number) => {
  return axios.post(`${baseURL}/${path}/add-all/${report_entry_id}`);
};

export const deleteReportEntry = (facilities: requestFormat) => {
  return axios.delete(`${baseURL}/${path}/remove-facilities`, { data: facilities });
};

export const deleteAllFacilityReportEntries = (report_entry_id: number) => {
  return axios.delete(`${baseURL}/${path}/remove-all-facilities/${report_entry_id}`);
};

export const saveReportEntry = (report_entry_id: number, report_entry: { responses: string }) => {
  return axios.patch(`${baseURL}/${path}/responses/${report_entry_id}`, report_entry);
};

export const submitFacilityReportEntry = (report_entry_id: number, report_entry: { responses: string }) => {
  return axios.patch(`${baseURL}/${path}/submit/${report_entry_id}`, report_entry);
};

export const approveFacilityReportEntry = (report_entry_id: number) => {
  return axios.patch(`${baseURL}/${path}/approve/${report_entry_id}`);
};

export const batchApproveFacilityReportEntries = (ids: number[]) => {
  return axios.patch(`${baseURL}/${path}/batch-approve`, { ids });
};

export const getReportEntryIndicators = (report_entry_id: number) => {
  return axios.get(`${baseURL}/${path}/responses/${report_entry_id}`);
};

export const getFacilityReportEntryIndicators = (report_entry_id: number, facility_report_entry_id: number) => {
  return axios.get(`${baseURL}/${path}/responses/${report_entry_id}/${facility_report_entry_id}`);
};

export const getReportEntryDashboardData = (report_entry_id: number) => {
  return axios.get(`${baseURL}/${path}/report-dashboard/${report_entry_id}`);
};
