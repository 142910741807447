import { Box, Chip, Typography, useTheme } from '@mui/material';
import { CustomContainer, RegularButton } from 'components';
import { CustomPCFEquipmentTable, CustomPCFTable, PageStyle } from 'screens/PCFTable/CustomPCFTable';
import { pcf_document, pcf_equipment_document } from 'screens/PCFTable/pcf_document';
import { useContext, useEffect, useState } from 'react';

import { BreadcrumbContext } from 'context/breadcrumb.context';
import { FacilityAssessmentModel } from 'model/Entities';
import LoadingScreen from 'screens/Helper/loading';
import { PCFItem } from 'data/DashboardTemplates';
import { UserContext } from 'context/user.context';
import { formatArray } from 'utils';
import { getBatchDashboardData } from 'api/assessment-batch-facilities';
import { getFacilityAssessment } from 'api/assessment';
import styled from 'styled-components';
import { tokens } from 'context/theme.context';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

const baseURL = process.env.REACT_APP_BASE_URL;

const PCFTable = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [pcfRef, setpcfRef] = useState<HTMLDivElement | null>(null);
  const [toPrint, setToPrint] = useState(false);
  const [loading, setLoading] = useState(true);
  const [assessment, setAssessment] = useState<FacilityAssessmentModel>();
  const [responses, setResponses] = useState<any>();
  const [equipmentFields, setEquipmentFields] = useState<any>({});
  const [pcfFields, setPCFFields] = useState<PCFItem[]>([]);
  const [pcfEquipmentFields, setPCFEquipmentFields] = useState<any[]>([]);
  const [pcfFacilityInfoFields, setPCFFacilityFields] = useState<any>();

  const StyledText = styled.p`
    font-family: 'Times New Roman';
    font-size: 14px;
    margin: 0;
  `;

  const StyledFormField = styled.p`
    font-family: 'Times New Roman';
    margin: 0;
    margin-bottom: 3px;
    font-size: 14px;
    border-bottom: 0.7px solid ${colors.text};
  `;

  const getResponses = async () => {
    try {
      setLoading(true);
      let res = await getFacilityAssessment(parseInt(id!));

      const assessment = res.data;
      setAssessment(assessment);
      let dasboard = await getBatchDashboardData(assessment.batch_id, assessment.indicator_id, assessment.facility_id);
      setResponses(dasboard.data[0]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && assessment) {
      const template = user.dashboard_templates.find(
        (template) => template.dashboard_code === assessment.assessment_batch.dashboard_code
      );
      if (template && template.pcf) {
        setPCFFacilityFields(template.pcf[0]);
        setPCFFields(template.pcf[1]);
        setPCFEquipmentFields(template.pcf[2]);
      } else {
        setPCFFacilityFields(undefined);
        setPCFFields([]);
        setPCFEquipmentFields([]);
      }
    }
  }, [user, assessment]);

  useEffect(() => {
    if (!id) return;
    getResponses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const generatePDF = useReactToPrint({
    documentTitle: 'PCF Table',
    content: () => pcfRef,
    pageStyle: PageStyle,
  });

  useEffect(() => {
    if (pcfRef && toPrint) {
      generatePDF();
      setToPrint(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toPrint]);

  useEffect(() => {
    if (assessment)
      setBreadcrumb([
        { label: 'Monitor', link: '/monitor' },
        { label: assessment.facility.facility_name, link: `/monitor/${assessment.id}` },
        { label: 'PCF Tool' },
      ]);
  }, [assessment, setBreadcrumb]);

  useEffect(() => {
    const equipment = pcfEquipmentFields.flatMap((item) =>
      item.equipment.flatMap((field: any) =>
        field.field_code.map((code: any, index: number) => {
          return {
            field_name: field.variant ? field.variant[index] : field.name,
            field_code: code,
            required_response: field.required_response ?? 'Available & Functional',
          };
        })
      )
    );
    setEquipmentFields(
      equipment.reduce((result, item) => {
        result[item.field_name] = item;
        return result;
      }, {})
    );
  }, [pcfEquipmentFields]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box display="flex" flexDirection="column" gap="20px">
      <CustomContainer>
        {assessment && (
          <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
            <Box>
              <Typography variant="h3" color={colors.text} fontWeight="bold" sx={{ m: '0 0 5px 0' }}>
                {assessment.facility.facility_name}
              </Typography>
              <Typography variant="h6" color={colors.text} sx={{ gridColumn: 'span 2' }}>
                {assessment.facility.facility_type.type}
                {assessment.facility.with_sbf && (
                  <Chip label="with SBF" size="small" variant="outlined" sx={{ marginLeft: '5px', fontSize: '10px' }} />
                )}
              </Typography>
              <Typography variant="subtitle1" color={colors.text}>
                {formatArray([
                  assessment.facility.municipality.municipality_name,
                  assessment.facility.province.province_name,
                  assessment.facility.barangay_name,
                ])}
              </Typography>
            </Box>
            <RegularButton label="Generate PDF" onClick={() => setToPrint(true)} disabled={!pcfFields.length} />
          </Box>
        )}
      </CustomContainer>
      {pcfFields.length ? (
        <CustomContainer>
          {responses && assessment ? (
            <Box
              sx={{
                width: '100%',
                maxWidth: 900,
                margin: '20px auto',
                '& p': { fontFamily: 'Times New Roman !important' },
              }}
            >
              <Box ref={(el: any) => setpcfRef(el)}>
                {/* Header */}
                <Box sx={{ textAlign: 'center', marginBottom: '50px', position: 'relative' }}>
                  <img
                    style={{ position: 'absolute', left: 0 }}
                    alt="logo"
                    width="80px"
                    height="auto"
                    src={`../../assets/DOH.png`}
                  />
                  <StyledText style={{ fontSize: '15px' }}>Republic of the Philippines</StyledText>
                  <StyledText style={{ fontSize: '15px' }}> Department of Health </StyledText>
                  <StyledText style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    HEALTH FACILITIES AND SERVICES REGULATORY BUREAU
                  </StyledText>
                </Box>

                <Box mb="50px">
                  <StyledText
                    style={{
                      textAlign: 'center',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      marginBottom: '20px',
                    }}
                  >
                    ASSESSMENT TOOL FOR LICENSING A PRIMARY CARE FACILITY
                  </StyledText>
                  <Box>
                    <StyledText style={{ fontWeight: 'bold' }}>INSTRUCTIONS:</StyledText>
                    <ol>
                      <li>
                        <StyledText>
                          To properly fill-out this tool, the Licensing Officer shall make use of: INTERVIEWS, REVIEW OF
                          DOCUMENTS, OBSERVATIONS and VALIDATION of findings.
                        </StyledText>
                      </li>
                      <li>
                        <StyledText>
                          If the corresponding items are present, available or adequate, place (/) on each of the
                          appropriate spaces under the FINDINGS column or space provided alongside each corresponding
                          item. If not, put an (X) instead.
                        </StyledText>
                      </li>
                      <li>
                        <StyledText>The REMARKS column shall document relevant observations.</StyledText>
                      </li>
                      <li>
                        <StyledText>
                          Make sure to fill-in the blanks with the needed information. Do not leave any items blank.
                        </StyledText>
                      </li>
                      <li>
                        <StyledText>
                          The Team Leader shall ensure that all team members write down their printed names, designation
                          and affix their signatures and indicate the date of inspection/monitoring, all at the last
                          page of the tool.
                        </StyledText>
                      </li>
                      <li>
                        <StyledText>
                          The Team Leader shall make sure that the Head of the facility or, when not available, the next
                          most senior or responsible officer likewise affixes his/her signature on the same
                          aforementioned pages, to signify that the inspection/monitoring results were discussed during
                          the exit conference and a duplicate copy was also received.
                        </StyledText>
                      </li>
                    </ol>
                  </Box>

                  <StyledText style={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '30px' }}>
                    GENERAL INFORMATION:
                  </StyledText>
                  {pcfFacilityInfoFields && (
                    <Box
                      display="grid"
                      gridTemplateColumns="180px 1fr 100px 1fr 80px 1fr"
                      columnGap="20px"
                      rowGap="5px"
                      paddingX="20px"
                    >
                      <StyledText>Name of Primary Care Facility:</StyledText>
                      <StyledFormField style={{ gridColumn: 'span 5' }}>
                        {assessment.facility.facility_name}
                      </StyledFormField>
                      <StyledText>Address:</StyledText>
                      <StyledFormField style={{ gridColumn: 'span 5' }}>
                        {formatArray([
                          assessment.facility.building_name,
                          assessment.facility.street_name,
                          assessment.facility.barangay_name,
                          assessment.facility.municipality.municipality_name,
                          assessment.facility.province.province_name,
                        ])}
                      </StyledFormField>

                      <StyledText>Telephone/Fax No.:</StyledText>
                      <StyledFormField>{responses[pcfFacilityInfoFields['Telephone/Fax No']]}</StyledFormField>
                      <StyledText>E-mail Address:</StyledText>
                      <StyledFormField style={{ gridColumn: 'span 3' }}>
                        {responses[pcfFacilityInfoFields['Email']]}
                      </StyledFormField>

                      <StyledText>Initial:</StyledText>
                      <StyledFormField>
                        {['Initial', 'Initial with changes'].includes(
                          responses[pcfFacilityInfoFields['PCF Application Status']]
                        )
                          ? '✓'
                          : ''}
                      </StyledFormField>
                      <StyledText>Renewal:</StyledText>
                      <StyledFormField>
                        {['Renewal'].includes(responses[pcfFacilityInfoFields['PCF Application Status']]) ? '✓' : ''}
                      </StyledFormField>
                      <StyledText style={{ gridColumn: 'span 2' }}></StyledText>

                      <StyledText>Existing License No:</StyledText>
                      <StyledFormField> {responses[pcfFacilityInfoFields['Existing License No']]}</StyledFormField>
                      <StyledText>Date Issued:</StyledText>
                      <StyledFormField>{responses[pcfFacilityInfoFields['Date Issued']]}</StyledFormField>
                      <StyledText>Expiry Date:</StyledText>
                      <StyledFormField>{responses[pcfFacilityInfoFields['Expiry Date']]}</StyledFormField>

                      <StyledText style={{ gridColumn: 'span 3' }}>
                        Name of Owner or Governing Body (if corporation):
                      </StyledText>
                      <StyledFormField style={{ gridColumn: 'span 3' }}>
                        {responses[pcfFacilityInfoFields['Managing Authority']]}
                      </StyledFormField>
                      <StyledText style={{ gridColumn: 'span 3' }}>Name of Head of Primary Care Facility:</StyledText>
                      <StyledFormField style={{ gridColumn: 'span 3' }}>
                        {responses[pcfFacilityInfoFields['Name of Head']]}
                      </StyledFormField>
                      <StyledText style={{ gridColumn: 'span 6' }}>Classification:</StyledText>
                      <StyledText>Ownership:</StyledText>
                      <StyledFormField>{responses[pcfFacilityInfoFields['Ownership']]}</StyledFormField>
                    </Box>
                  )}
                </Box>

                <StyledText style={{ fontSize: '15px', fontWeight: 'bold', marginBottom: '10px' }}>
                  PART I. SERVICE CAPABILITY, PERSONNEL AND PHYSICAL PLANT
                </StyledText>
                <CustomPCFTable tableData={pcf_document} responses={responses} pcfItems={pcfFields} />
                <br />
                <StyledText style={{ fontSize: '15px', fontWeight: 'bold', marginBottom: '10px' }}>
                  PART II. EQUIPMENT AND INSTRUMENTS
                </StyledText>
                <CustomPCFEquipmentTable
                  tableData={pcf_equipment_document}
                  responses={responses}
                  equipmentFields={equipmentFields}
                />
                <Box display="flex" flexDirection="column" alignItems="center" mt="20px" gap="5px">
                  <img alt="logo" width="auto" height="35px" src={baseURL + '/assets/fresaa-logo.png'} />
                  <Typography fontSize="15px" textAlign="center">
                    Facility Readiness and Essential Service Availability Assessment (FRESAA)
                    <br />
                    Project of the World Health Organization
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="flex-end" gap="10px" mt="10px">
                  <Typography fontSize="12px">Developed by CP Health Innovations Inc.</Typography>
                  <img alt="logo" width="auto" height="20px" src={baseURL + '/assets/cphi_logo.png'} />
                </Box>
              </Box>
            </Box>
          ) : (
            <Typography>Assessment is not yet completed, pending approvals from all relevant bodies.</Typography>
          )}
        </CustomContainer>
      ) : (
        <CustomContainer>
          <Typography>PCF Table is not available</Typography>
        </CustomContainer>
      )}
    </Box>
  );
};

export default PCFTable;
