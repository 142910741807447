import { DashboardWidget } from 'screens/Dashboard/charts/chartDataProcessor';

const COLORS = {
  GREEN: '#81ca9c',
  YELLOW: '#ffc658',
  ORANGE: '#f6914f',
  RED: '#f6665f',
  BLUE: '#54BAD1',
  PURPLE: '#8784d8'
};

export const EasternVisayasAHDPDashboard1: DashboardWidget[] = [
  {
    type: 'pie',
    title: 'Facilities Assessed',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 3 },
    xAxisDataKeys: [{ key: 'Facility Type' }],
    yAxisDataKeys: [
      { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
      { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
      { key: 'Infirmary', chartColor: COLORS.BLUE },
      { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
    ],
  },
  {
    type: 'bar',
    title: 'Facilities Assessed per Municipality',
    label: { xAxisLabel: 'Municipality', yAxisLabel: 'Count' },
    containerDimnesion: { colSpan: 9 },
    xAxisDataKeys: [{ key: 'Municipality' }],
    useXAxisDataKeyResponseAsXAxis: true,
    yAxisDataKeys: [
      { key: 'Count',  chartColor: COLORS.BLUE }, 
    ],
    yAxisBreakdown: {
      breakdownKey: 'Facility Type',
      yAxisBreakdownKeys: [
        { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
        { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
        { key: 'Infirmary', chartColor: COLORS.BLUE },
        { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
      ],
    },
  },
  {
    type: 'bar',
    title: 'HEADSS Usage and Service Access',
    label: { xAxisLabel: '', yAxisLabel: 'Count' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'sum',
    xAxisDataKeys: [
      { key: 'STI' },
      { key: 'HIV' },
      { key: 'Attempted Suicide' },
      { key: 'Committed Suicide' },
      { key: 'Sexual Violence' },
    ],
    yAxisDataKeys: [
      { key: 'Count', chartColor: COLORS.GREEN },
    ],
    dataPreprocessor: (data) => {
      const stiSourceFields = ['001F-1', '001F-2', '001F-3', '001F-4'];
      const hivSourceFields = ['001G-1', '001G-2', '001G-3', '001G-4'];
      const aSuicideSourceFields = ['001J-1', '001J-2', '001J-3', '001J-4'];
      const cSuicideSourceFields = ['001K-1', '001K-2', '001K-3', '001K-4'];
      const sexualSourceFields = ['001N-1', '001N-2', '001N-3', '001N-4'];

      const counter = (row: any, sourceFields: string[]) => {
        return sourceFields.reduce((result, field) => {
          const response = row[field];
          console.log(response)
          return result + (isNaN(parseFloat(response)) ? 0 : parseFloat(response));
        }, 0);
      };

      data.forEach((row) => {
        row['STI'] = counter(row, stiSourceFields);
        row['HIV'] = counter(row, hivSourceFields);
        row['Attempted Suicide'] = counter(row, aSuicideSourceFields);
        row['Committed Suicide'] = counter(row, cSuicideSourceFields);
        row['Sexual Violence'] = counter(row, sexualSourceFields);
      });
      
      return data;
    },
  },
  {
    type: 'bar',
    title: 'Live Birth Among Adolescents',
    label: { xAxisLabel: 'Age', yAxisLabel: 'Count' },
    containerDimnesion: { colSpan: 4 },
    aggregation: 'sum',
    xAxisDataKeys: [
      { key: '002C-1', displayName: '10-14 Years Old' },
      { key: '002C-2', displayName: '15-19 Years Old' },
    ],
    yAxisDataKeys: [
      { key: 'Count', chartColor: COLORS.PURPLE },
    ],
  },
  {
    type: 'bar',
    title: 'Maternal Death Among Adolescents',
    label: { xAxisLabel: 'Age', yAxisLabel: 'Count' },
    containerDimnesion: { colSpan: 4 },
    aggregation: 'sum',
    xAxisDataKeys: [
      { key: '002B-1', displayName: '10-14 Years Old' },
      { key: '002B-2', displayName: '15-19 Years Old' },
    ],
    yAxisDataKeys: [
      { key: 'Count', chartColor: COLORS.ORANGE },
    ],
  },
  {
    type: 'bar',
    title: 'Teenage Pregnancy',
    label: { xAxisLabel: 'Age', yAxisLabel: 'Count' },
    containerDimnesion: { colSpan: 4 },
    aggregation: 'sum',
    xAxisDataKeys: [
      { key: '002A-1', displayName: '10-14 Years Old' },
      { key: '002A-2', displayName: '15-19 Years Old' },
    ],
    yAxisDataKeys: [
      { key: 'Count', chartColor: COLORS.BLUE },
    ],
  }
];
