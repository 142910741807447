import { useContext, useEffect } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTabs from 'components/CustomTabs';
import { Header } from 'components';
import Home from './Home';
import ReportsDashboard from './ReportsDashboard';
import { isFresaarProject } from 'utils';

const Dashboard = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap="20px">
      <Header title="DASHBOARD" subtitle="Welcome to your dashboard" mb="0" />
      <Box>
        {isFresaarProject() ? (
          <CustomTabs
            tabs={[
              { label: 'External', content: <Home /> },
              { label: 'Internal', content: <ReportsDashboard /> },
            ]}
          />
        ) : (
          <Home />
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
