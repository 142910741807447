/* eslint-disable react-hooks/exhaustive-deps */

import { AssessmentBatchFormModel, AssessmentBatchModel, FacilityAssessmentModel } from 'model/Entities';
import { Box, Button, Menu, MenuItem, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomGridCell,
  CustomIconButton,
  Header,
  ProtectedComponent,
  RegularButton,
  RowAction,
} from 'components';
import CustomTabs, { TabProps } from 'components/CustomTabs';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import {
  createAssessmentBatchForm,
  deleteAssessmentBatchForm,
  getAssessmentBatchForms,
  getAvailableAssessmentBatchForms,
} from 'api/assessment-batch-form';
import {
  deleteAllFacilitiesInBatch,
  deleteAssesmentBatch,
  getByBatchId,
  getFacilityAssessmentIndicators,
  getIndividualFacilityAssessmentIndicators,
} from 'api/assessment-batch-facilities';
import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'model/interface';
import CloseIcon from '@mui/icons-material/Close';
import CustomTable from 'components/CustomTable';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import FacilityAssessmentAddModal from './FacilityAssessmentAddModal';
import { Link } from 'react-router-dom';
import LoadingScreen from 'screens/Helper/loading';
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { getFacilityAssessments } from 'api/assessment';
import { getStatusChip } from 'utils';
import saveAs from 'file-saver';
import { tokens } from 'context/theme.context';
import { updateAssessmentBatchStatus } from 'api/assessment-batch';
import { useSnackbar } from 'notistack';

type FacilityAssessmentProps = {
  selectedBatch?: number;
  batch?: AssessmentBatchModel;
  refreshAssessment: () => void;
  handleClose: () => void;
};

const FacilityAssessment: React.FC<FacilityAssessmentProps> = ({
  refreshAssessment,
  selectedBatch,
  batch,
  handleClose,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { enqueueSnackbar } = useSnackbar();
  const [changable, setChangable] = useState(true);
  const [rows, setRows] = useState<FacilityAssessmentModel[]>([]);
  const [forms, setForms] = useState<any[]>([]);
  const [assessments, setAssessments] = useState<FacilityAssessmentModel[]>([]);
  const [availableForms, setAvailableForms] = useState<any[]>([]);

  const [formsRowCount, setFormsRowCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [assessmentsCount, setAssessmentsCount] = useState(0);

  const [loading, setLoading] = useState(false);
  const [loadingForms, setLoadingForms] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteAllFacilities, setOpenDeleteAllFacilities] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openDeleteFormConfirmation, setOpenDeleteFormConfirmation] = useState(false);
  const [openDeleteConfirmationAll, setOpenDeleteConfirmationAll] = useState(false);
  const [openStatusUpdate, setOpenStatusUpdate] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [statusUpdating, setStatusUpdating] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [statusAction, setStatusAction] = useState<string>('');
  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUnclick = () => {
    setAnchorEl(null);
  };

  const exportAsCsv = (csvBlob: Blob, fileName: string) => {
    saveAs(csvBlob, fileName);
  };

  const dropDownDownloadResponses = (data: any) => {
    console.log(data);
    const { batch_id, indicator_id, facility_id, facility, assessment_batch } = data;
    setDownloadLoading(true);
    getIndividualFacilityAssessmentIndicators(batch_id, indicator_id, facility_id)
      .then((response) => {
        if (!response.data.csvFormatted)
          return enqueueSnackbar('Assessment Indicator Unavailable', { variant: 'warning' });
        const blobData = new Blob([response.data.csvFormatted], { type: 'text/csv' });
        exportAsCsv(blobData, `${facility.facility_name} - ${assessment_batch.batch_name}.csv`);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  // Table Columns
  const formColumns: GridColDef[] = [
    {
      field: 'name',
      sortable: false,
      headerName: 'Form Indicator Name',
      flex: 2,
      renderCell: (params) => <CustomGridCell>{params.row.indicator.name}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Box display="flex" gap="5px">
            {batch?.status === 'open' ? (
              <ProtectedComponent requiredAuth={['MANAGE_FRESAA_BATCHES']}>
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => {
                    setSelectedFacility(params.row.id);
                    setOpenDeleteFormConfirmation(true);
                  }}
                >
                  Delete
                </Button>
              </ProtectedComponent>
            ) : (
              <RegularButton
                variant="outlined"
                size="small"
                color="success"
                loading={downloadLoading}
                onClick={() => {
                  setDownloadLoading(true);
                  // do the saving here
                  const { batch_id, indicator_id, indicator } = params.row;
                  getFacilityAssessmentIndicators(batch_id, indicator_id)
                    .then((response) => {
                      if (!response.data.csvFormatted)
                        return enqueueSnackbar('Assessment Indicator Unavailable', { variant: 'warning' });
                      const blobData = new Blob([response.data.csvFormatted], { type: 'text/csv' });
                      exportAsCsv(blobData, indicator.name + '.csv');
                    })
                    .finally(() => {
                      setDownloadLoading(false);
                    });
                }}
                startIcon={<DownloadOutlinedIcon />}
                label="Download Facility Assessments"
              />
            )}
          </Box>
        );
      },
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'facility_id',
      sortable: false,
      headerName: 'Facility Name',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.facility.facility_name}
          {params.row.facility.deleted_at && (
            <span
              style={{
                background: colors.redAccent,
                color: 'white',
                fontSize: '10px',
                padding: '1px 5px 2px',
                borderRadius: '5px',
                marginLeft: '10px',
              }}
            >
              Inactive
            </span>
          )}
        </CustomGridCell>
      ),
    },
    {
      field: 'facility_type_id',
      sortable: false,
      headerName: 'Facility Type',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.facility.facility_type.type}</CustomGridCell>,
    },
    {
      field: 'province_code',
      sortable: false,
      headerName: 'Location',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.facility.municipality.municipality_name}
          {', '}
          {params.row.facility.province.province_name}
          {', '}
          {params.row.facility.barangay_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <ProtectedComponent requiredAuth={['MANAGE_FRESAA_BATCHES']}>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              setSelectedFacility(params.row.id);
              setOpenDeleteConfirmation(true);
            }}
          >
            Delete
          </Button>
        </ProtectedComponent>
      ),
    },
  ];

  const assessmentColumns: GridColDef[] = [
    {
      field: 'facility_id',
      sortable: false,
      headerName: 'Facility Name',
      flex: 1.5,
      renderCell: (params) => {
        return (
          <CustomGridCell>
            <Link
              to={`/monitor/${params.row.id}`}
              style={{
                color: colors.accent,
                textDecoration: 'none',
                width: '100%',
              }}
            >
              {params.row.facility.facility_name}
            </Link>
          </CustomGridCell>
        );
      },
    },
    {
      field: 'facility_type_id',
      sortable: false,
      headerName: 'Facility Type',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.facility.facility_type.type}</CustomGridCell>,
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{getStatusChip(params.row.status, theme, colors)}</CustomGridCell>,
    },
    {
      field: 'assessor_id',
      sortable: false,
      headerName: 'Assessor',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.assessor?.first_name} {params.row.assessor?.last_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'indicator_id',
      sortable: false,
      headerName: 'Indicators',
      flex: 1.5,
      renderCell: (params) => <CustomGridCell>{params.row.assessment_indicator?.name}</CustomGridCell>,
    },
    // action here
    {
      field: 'actions',
      sortable: false,
      headerName: 'Actions',

      renderCell: (params) => (
        <RowAction
          actions={[
            {
              label: 'Download Responses',
              action: dropDownDownloadResponses,
              startIcon: <DownloadOutlinedIcon />,
            },
          ]}
          data={params.row}
          // disabled={['open', 'assigned', 'started'].includes(params.row.status)}
        />
      ),
    },
  ];

  // Functions
  const getFacilityInBatch = (query?: ApiQuery) => {
    if (selectedBatch) {
      setLoading(true);
      getByBatchId(selectedBatch, {
        page: 1,
        length: 10,
        ...query,
      })
        .then((res) => {
          setRows(res.data.rows);
          setRowCount(res.data.count);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      setRows([]);
      setRowCount(0);
    }
  };

  const getFacilityAssessmentsInBatch = (query?: ApiQuery) => {
    if (selectedBatch) {
      setLoading(true);
      getFacilityAssessments(selectedBatch, {
        page: 1,
        length: 10,
        ...query,
      })
        .then((res) => {
          setAssessments(res.data.rows);
          setAssessmentsCount(res.data.count);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      setAssessments([]);
      setAssessmentsCount(0);
    }
  };

  const getForms = async (query?: ApiQuery) => {
    setForms([]);
    if (selectedBatch) {
      setLoadingForms(true);
      try {
        const res = await getAssessmentBatchForms(selectedBatch, { page: 1, length: 10, ...query });
        setForms(res.data.rows);
        setFormsRowCount(res.data.count);
        setLoadingForms(false);
      } finally {
        setLoadingForms(false);
      }
    }
  };

  const getAvailableForms = async (query?: ApiQuery) => {
    setAvailableForms([]);
    if (selectedBatch) {
      const res = await getAvailableAssessmentBatchForms(selectedBatch);
      setAvailableForms(res.data);
    }
  };

  const handleRemoveAllFacilities = (batch_id: number) => {
    deleteAllFacilitiesInBatch(batch_id).then((res) => {
      enqueueSnackbar('All facilities removed', {
        variant: 'success',
      });
      getFacilityInBatch();
      setRefresh((prev: number) => prev + 1);
    });
  };

  const handleRemoveFacility = (facilities: any) => {
    deleteAssesmentBatch({ assessment_batch_facility_ids: facilities }).then((res) => {
      enqueueSnackbar('Facility assessment successfully deleted!', {
        variant: 'success',
      });
      getFacilityInBatch();
      setRefresh((prev: number) => prev + 1);
    });
  };

  const handleRemoveForm = (id: number) => {
    deleteAssessmentBatchForm(id).then((res) => {
      enqueueSnackbar('Facility assessment form successfully deleted!', {
        variant: 'success',
      });
      getForms();
      getAvailableForms();
      setRefresh((prev: number) => prev + 1);
    });
  };

  const handleCreateAssessment = () => {
    setOpenModal(true);
  };

  const handleAddForm = async (form: AssessmentBatchFormModel) => {
    await createAssessmentBatchForm({ batch_id: batch?.id!, indicator_id: form.id! });
    setRefresh((prev: number) => prev + 1);
    getAvailableForms();
  };

  const handleUpdateStatus = async (batch_id: number) => {
    setStatusUpdating(true);
    if (statusAction === 'Reopen') {
      await updateAssessmentBatchStatus(batch_id, 'open');
      enqueueSnackbar('Changed status to open', { variant: 'success' });
    } else {
      switch (batch?.status) {
        case 'open':
          await updateAssessmentBatchStatus(batch_id, 'active');
          enqueueSnackbar('Changed status to active', { variant: 'success' });
          break;
        case 'active':
          await updateAssessmentBatchStatus(batch_id, 'closed');
          enqueueSnackbar('Changed status to closed', { variant: 'success' });
          break;
        case 'closed':
          await updateAssessmentBatchStatus(batch_id, 'active');
          enqueueSnackbar('Changed status to active', { variant: 'success' });
          break;
      }
    }

    refreshAssessment();
    setStatusUpdating(false);
    setStatusAction('');
  };

  const getStatusButtonColor = () => {
    return batch?.status === 'open'
      ? 'success'
      : batch?.status === 'active'
      ? 'warning'
      : batch?.status === 'closed'
      ? 'success'
      : 'success';
  };

  const getStatusButtonName = () => {
    return batch?.status === 'open'
      ? 'Activate Assessments'
      : batch?.status === 'active'
      ? 'Close Assessments'
      : batch?.status === 'closed'
      ? 'Reactivate Assessments'
      : '';
  };

  const getStatusAction = () => {
    if (statusAction === 'Reopen') {
      return 'Reopen Assessments';
    }
    return batch?.status === 'open'
      ? 'Activate Assessments'
      : batch?.status === 'active'
      ? 'Close Assessments'
      : batch?.status === 'closed'
      ? 'Reactivate Assessments'
      : '';
  };

  useEffect(() => {
    if (selectedBatch) {
      getFacilityInBatch();
      getFacilityAssessmentsInBatch();
      getForms();
      getAvailableForms();
      setChangable(batch ? !['active', 'closed'].includes(batch.status) : true);
    }
  }, [selectedBatch, batch]);

  useEffect(() => {
    if (!openStatusUpdate) setStatusAction('');
  }, [openStatusUpdate]);

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  const tabs: TabProps[] = [
    {
      label: 'Assessment Forms',
      icon: <LooksOneOutlinedIcon />,
      content: (
        <CustomTable
          columns={formColumns}
          rows={forms}
          rowCount={formsRowCount}
          loading={loadingForms}
          getData={getForms}
          rowHeight={45}
          forceRefresh={refresh}
          hideSearch={true}
          headerComponent={
            <ProtectedComponent requiredAuth={['MANAGE_FRESAA_BATCHES']}>
              <Box gap="15px" display="flex">
                <RegularButton
                  startIcon={<AddIcon />}
                  label={`Add Form`}
                  onClick={handleClick}
                  disabled={!availableForms.length}
                  styles={{
                    display: !changable ? 'none' : '',
                  }}
                />
                <Menu
                  id="assessment-add-form-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleUnclick}
                >
                  {availableForms.map((form) => (
                    <MenuItem
                      key={form.id}
                      onClick={() => {
                        handleAddForm(form);
                      }}
                    >
                      {form.name.toString()}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </ProtectedComponent>
          }
        />
      ),
    },
    {
      label: 'Facilities to be Assessed',
      icon: <LooksTwoOutlinedIcon />,
      content: (
        <CustomTable
          searchKeys="Facility Name"
          columns={changable ? columns : columns.filter((col) => col.field !== 'action')}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          getData={getFacilityInBatch}
          rowHeight={35}
          checkboxSelection={changable}
          isRowSelectable={changable}
          disableSelectionOnClick
          handleSelectRow={setSelectionModel}
          forceRefresh={refresh}
          headerComponent={
            <Box gap="15px" display="flex">
              <ProtectedComponent requiredAuth={['MANAGE_FRESAA_BATCHES']}>
                <RegularButton
                  startIcon={<RemoveCircleIcon />}
                  onClick={() => {
                    setOpenDeleteConfirmationAll(true);
                  }}
                  label={'Remove selected facilities'}
                  color="error"
                  disabled={!selectionModel.length}
                  styles={{
                    display: !changable ? 'none' : '',
                  }}
                />
                <RegularButton
                  startIcon={<AddIcon />}
                  onClick={handleCreateAssessment}
                  label={'Add Facilities'}
                  styles={{
                    display: !changable ? 'none' : '',
                  }}
                />
                <RegularButton
                  startIcon={<DeleteForeverIcon />}
                  onClick={() => setOpenDeleteAllFacilities(true)}
                  label={'Remove All'}
                  color="error"
                  styles={{
                    display: !changable ? 'none' : '',
                  }}
                />
              </ProtectedComponent>
            </Box>
          }
        />
      ),
    },
    {
      label: 'Assessments',
      icon: <Looks3OutlinedIcon />,
      content: (
        <CustomTable
          searchKeys="Facility Name"
          columns={assessmentColumns}
          rows={assessments}
          rowCount={assessmentsCount}
          loading={loading}
          getData={getFacilityAssessmentsInBatch}
          headerComponent={
            <Box gap="10px" display="flex">
              <RegularButton
                startIcon={<RefreshIcon />}
                onClick={() => getFacilityAssessmentsInBatch()}
                label={'Refresh'}
              />
              <ProtectedComponent requiredAuth={['MANAGE_FRESAA_BATCHES']}>
                <RegularButton
                  onClick={() => setOpenStatusUpdate(true)}
                  color={getStatusButtonColor()}
                  label={getStatusButtonName()}
                  loading={statusUpdating}
                />
                {batch?.status === 'active' && (
                  <RegularButton
                    onClick={() => {
                      setStatusAction('Reopen');
                      setOpenStatusUpdate(true);
                    }}
                    color="info"
                    label="Reopen Assessments"
                    loading={statusUpdating}
                  />
                )}
              </ProtectedComponent>
            </Box>
          }
        />
      ),
    },
  ];

  return (
    <Box>
      <CustomContainer>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
          <Header title={batch ? `${batch.batch_name} (${batch.year})` : ''} mb="0" />
          <CustomIconButton icon={<CloseIcon />} onClick={handleClose} />
        </Box>

        <Box width="100%">
          <CustomTabs tabs={tabs} tabBackgroundColor={colors.solidHoverBackground} tabMinWidth="210px" />
        </Box>
      </CustomContainer>

      {/* Facility Assessment */}
      {selectedBatch && (
        <FacilityAssessmentAddModal
          open={openModal}
          setOpen={setOpenModal}
          selectedBatch={selectedBatch!}
          refreshBatchTable={refreshTable}
        />
      )}

      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure want to remove this facility?"
        onConfirm={() => handleRemoveFacility([selectedFacility])}
      />

      <ConfirmationDialog
        open={openDeleteConfirmationAll}
        setOpen={setOpenDeleteConfirmationAll}
        message="Are you sure want to remove these facilities?"
        onConfirm={() => handleRemoveFacility(selectionModel)}
      />

      <ConfirmationDialog
        open={openDeleteFormConfirmation}
        setOpen={setOpenDeleteFormConfirmation}
        message="Are you sure want to remove these form?"
        onConfirm={() => handleRemoveForm(selectedFacility)}
      />

      <ConfirmationDialog
        open={openDeleteAllFacilities}
        setOpen={setOpenDeleteAllFacilities}
        message="Are you sure want to remove all the facilities?"
        onConfirm={() => (batch?.id ? handleRemoveAllFacilities(batch?.id) : undefined)}
      />

      <ConfirmationDialog
        open={openStatusUpdate}
        setOpen={setOpenStatusUpdate}
        message={`Are you sure want to ${getStatusAction()}? ${
          getStatusAction() === 'Reopen Assessments'
            ? 'Reopening assessment batch will delete the assessments not yet submitted'
            : ''
        }`}
        onConfirm={() => (batch?.id ? handleUpdateStatus(batch?.id) : undefined)}
      />
    </Box>
  );
};

export default FacilityAssessment;
