/* eslint-disable react-hooks/exhaustive-deps */

import { Box, useTheme } from '@mui/material';
import {
  ConditionalHideComponent,
  CustomContainer,
  CustomGridCell,
  FacilityLocationFilter,
  Header,
  RegularButton,
} from 'components';
import { getStatusChip, isFresaarProject } from 'utils';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'model/interface';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTable from 'components/CustomTable';
import { FacilityAssessmentModel } from 'model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import ReportEntriesWithFacilityReportEntries from 'screens/Reports/ReportEntriesWithFacilityReportEntries';
import { getAllAssessments } from 'api/assessment';
import { tokens } from 'context/theme.context';

const AssessmentFiles = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [rows, setRows] = useState<FacilityAssessmentModel[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const [otherFilters, setOtherFilters] = useState<any>({});

  // Table Columns

  const columns: GridColDef[] = [
    {
      field: 'facility_id',
      sortable: false,
      headerName: 'Facility Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          <Link
            to={`/assessment-files/${params.row.id}`}
            style={{
              color: colors.accent,
              textDecoration: 'none',
              width: '100%',
            }}
          >
            {params.row.facility.facility_name}
          </Link>
        </CustomGridCell>
      ),
    },
    {
      field: 'batch',
      sortable: false,
      headerName: 'Batch',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>{`${params.row.assessment_batch.batch_name} - ${params.row.assessment_indicator.name}`}</CustomGridCell>
      ),
    },
    {
      field: 'facility_type',
      sortable: false,
      headerName: 'Facility Type',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.facility.facility_type.type}</CustomGridCell>,
    },
    {
      field: 'province_code',
      sortable: false,
      headerName: 'Location',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.facility.municipality.municipality_name}
          {', '}
          {params.row.facility.province.province_name}
          {', '}
          {params.row.facility.barangay_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'assessor_id',
      sortable: false,
      headerName: 'Assessor',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.assessor?.title} {params.row.assessor?.first_name} {params.row.assessor?.last_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{getStatusChip(params.row.status, theme, colors)}</CustomGridCell>,
    },
  ];

  // Functions
  const getAssessments = (query?: ApiQuery) => {
    setLoading(true);
    getAllAssessments({
      page: 1,
      length: 10,
      ...query,
      status: 'completed',
    })
      .then((res) => {
        setRows(res.data.rows);
        setRowCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Assessments and Reports' }]);
  }, []);

  const clearFilter = () => {
    // Set all values to empty string
    for (let key in otherFilters) {
      otherFilters[key] = '';
    }
    setOtherFilters({ ...otherFilters });
  };

  return (
    <Box display="flex" flexDirection="column" gap="20px">
      <Header
        title={`Facility Assessments${isFresaarProject() ? ' and Reports' : ''}`}
        subtitle={`List of all approved assessments${isFresaarProject() ? ' and reports' : ''}`}
        mb="10px"
      />
      <CustomContainer>
        <CustomTable
          searchKeys="Facility Name, or Batch Name"
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          getData={getAssessments}
          otherFilters={otherFilters}
          rowHeight={35}
          headerComponent={
            <Box>
              <ConditionalHideComponent hide={!isFresaarProject()}>
                <Header title={`Facility Assessments${isFresaarProject() ? ' (External)' : ''}`} />
              </ConditionalHideComponent>
              <Box display="flex" gap="10px" width="100%" alignItems="center">
                <FacilityLocationFilter
                  otherFilters={otherFilters}
                  handleChangeFilter={(data) => setOtherFilters({ ...otherFilters, ...data })}
                  showFacilityDropdown
                />
                <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
              </Box>
            </Box>
          }
        />
      </CustomContainer>
      <ConditionalHideComponent hide={!isFresaarProject()}>
        <ReportEntriesWithFacilityReportEntries reportStatus={'completed'} />
      </ConditionalHideComponent>
    </Box>
  );
};

export default AssessmentFiles;
