import ArticleContent, { imagePath } from '../ArticleContent';

const UpdateIndicatorArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          title: 'Updating Indicator',
          steps: [
            {
              step: '1. Select the the indicator, then scroll down to see the editor.',
              image: `${imagePath}/update-indicator-1.jpg`,
            },
            { step: '2. Edit the indicator, then click "Save".', image: `${imagePath}/update-indicator-2.jpg` },
          ],
        },
        {
          title: 'Indicator Editor',
          divider: true,
          steps: [{ step: 'In this section, the types of input and components and its purpose are listed:' }],
        },
        {
          subTitle: 'Types of input',
          titleGutterTop: true,
          bullet: [
            { content: 'Text' },
            { content: 'Number' },
            { content: 'Yes or No' },
            { content: 'Select one from the list of options (Radio Group)' },
          ],
        },
        {
          subTitle: 'Components',
          titleGutterTop: true,
          bullet: [
            { content: 'MainSections/Sections' },
            { content: 'Subsections' },
            { content: 'Parents' },
            {
              content: 'Input Fields',
              children: [
                { content: 'Text Field' },
                { content: 'Numeric Field' },
                { content: 'Radio Group' },
                { content: 'Yes or No' },
                { content: 'Simple Table', children: [{ content: 'Like a Radio Group Field but in tabular form' }] },
                {
                  content: 'ComplexTable',
                  children: [
                    { content: 'Different columns can accept different types of input' },
                    { content: 'Example: How many doctors, nurses, pharmacists are full time or part time?' },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'MainSections, Sections, and Subsections',
          titleGutterTop: true,
          bullet: [
            { content: 'MainSections - are the main containers in the mobile application.' },
            { content: 'Sections - are the sections displayed inside the MainSections container.' },
            {
              content:
                'Subsections - are the subsections that can appear when the sections are clicked. Not all sections have subsections.',
            },
          ],
          steps: [
            {
              step: 'I. In the example image below: "Section 2" is the "Main Section" and "Section 2.1 - 2.6" is the "Section": ',
              image: `${imagePath}/sections-1.jpg`,
            },
            {
              step: 'II. In the image below, the "Section 2.6.1" is an example of subsection:',
              image: `${imagePath}/sections-2.jpg`,
              imageWidth: '400px',
            },
            {
              step: 'III. Example Indicator Code for MainSection, Sections, and Subsections:',
              image: `${imagePath}/sample-section-code.png`,
            },
          ],
        },
        {
          title: 'Parents',
          titleGutterTop: true,
          steps: [
            {
              step: 'Parents are items that will provide context to the child indicators. In the image below, the item "012" is an example of Parent item:',
              image: `${imagePath}/parent.png`,
              imageWidth: '400px',
            },
            { step: '', image: `${imagePath}/parent-sample.png`, imageWidth: '800px' },
          ],
        },
        {
          title: 'Input Fields',
          steps: [{ step: 'Below are the example of different input fields, sample code and interface:' }],
        },
        {
          subTitle: 'Text Field',
          titleGutterTop: true,
        },
        {
          steps: [
            { step: '', image: `${imagePath}/text-field-sample.png`, imageWidth: '400px' },
            { step: '', image: `${imagePath}/text-field-code-sample.png`, imageWidth: '400px' },
          ],
        },
        {
          subTitle: 'Numeric Field',
          titleGutterTop: true,
          steps: [{ step: 'In the example below, inputs 001A & 001B are example of numeric field' }],
        },
        {
          steps: [
            { step: '', image: `${imagePath}/numeric-field-sample.png`, imageWidth: '400px' },
            { step: '', image: `${imagePath}/numeric-field-code-sample.png`, imageWidth: '400px' },
          ],
        },
        {
          subTitle: 'RadioGroup',
          titleGutterTop: true,
        },
        {
          steps: [
            { step: '', image: `${imagePath}/radio-group-field-sample.png`, imageWidth: '400px' },
            { step: '', image: `${imagePath}/radio-group-field-code-sample.png`, imageWidth: '600px' },
          ],
        },
        {
          subTitle: 'Yes or No',
          titleGutterTop: true,
        },
        {
          steps: [
            { step: '', image: `${imagePath}/yesno-field-sample.png`, imageWidth: '400px' },
            { step: '', image: `${imagePath}/yesno-field-code-sample.png` },
          ],
        },
        {
          subTitle: 'Simple Table',
          titleGutterTop: true,
        },
        {
          steps: [
            {
              step: 'The user view when answering the table:',
              image: `${imagePath}/simple-table-field-sample.png`,
              imageWidth: '400px',
            },
            {
              step: 'The user view after answering the table (preview):',
              image: `${imagePath}/simple-table-answered-sample.png`,
            },
            { step: '', image: `${imagePath}/simple-table-field-code-sample.png` },
          ],
        },
        {
          subTitle: 'Complex Table',
          titleGutterTop: true,
        },
        {
          steps: [
            {
              step: 'The user view when answering the table:',
              image: `${imagePath}/complex-table.png`,
              imageWidth: '400px',
            },
            {
              step: 'The user view after answering the table (preview):',
              image: `${imagePath}/complex-table-answered.png`,
            },
            { step: '', image: `${imagePath}/complex-table-answered-code.png` },
          ],
        },
      ]}
    />
  );
};

export default UpdateIndicatorArticle;
