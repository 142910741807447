import ArticleContent, { imagePath } from '../ArticleContent';

const CRUDReportArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          title: 'Creating a Report',
          steps: [
            { step: '1. In the Reporting Tool, Click "Add Report"', image: `${imagePath}/create-reporting-tool-1.png` },
            { step: '2. A modal will pop up, kindly fill up the modal.' },
            { step: '3. Click Submit', image: `${imagePath}/create-reporting-tool-2.png` },
          ],
        },
        {
          title: 'Updating a Report',
          steps: [
            {
              step: '1. Select the action button on the right side of the report, then click "Update".',
              image: `${imagePath}/report-edit-1.png`,
            },
            {
              step: '2. A modal will pop up where name and frequency can be updated, kindly update with the values preferred.',
            },
            { step: '3. Click "Submit" Button', image: `${imagePath}/report-edit-2.png` },
          ],
        },
        {
          title: 'Deleting a Report',
          steps: [
            {
              step: '1. Select the action button on the right side of the report, then click "Delete".',
              image: `${imagePath}/delete-report-1.png`,
            },
            {
              step: '2. A Confirmation dialog will show up, simply click "Yes" to delete.',
              image: `${imagePath}/delete-report-2.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default CRUDReportArticle;
