import { pcf_equipment, pcf_items } from 'data/Aklan/dashboard/AklanProvinceWide_V1/pcf_Aklan';

import { DashboardWidget } from 'screens/Dashboard/charts/chartDataProcessor';
import { Typography } from '@mui/material';
import { formatNumber } from 'utils';

const COLORS = {
  GREEN: '#81ca9c',
  YELLOW: '#ffc658',
  ORANGE: '#f6914f',
  RED: '#f6665f',
  BLUE: '#54BAD1',
  PURPLE: '#8784d8'
};

export const AklanDashboard1: DashboardWidget[] = [
  {
    type: 'pie',
    title: 'Facilities Assessed',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 3 },
    xAxisDataKeys: [{ key: 'Facility Type' }],
    yAxisDataKeys: [
      { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
      { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
      { key: 'Infirmary', chartColor: COLORS.BLUE },
      { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
    ],
  },
  {
    type: 'bar',
    title: 'Facilities Assessed per Municipality',
    label: { xAxisLabel: 'Municipality', yAxisLabel: 'Count' },
    containerDimnesion: { colSpan: 9 },
    xAxisDataKeys: [{ key: 'Municipality' }],
    useXAxisDataKeyResponseAsXAxis: true,
    yAxisDataKeys: [
      { key: 'Count',  chartColor: COLORS.BLUE }, 
    ],
    yAxisBreakdown: {
      breakdownKey: 'Facility Type',
      yAxisBreakdownKeys: [
        { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
        { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
        { key: 'Infirmary', chartColor: COLORS.BLUE },
        { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
      ],
    },
  },
  {
    type: 'bar',
    title: 'PhilHealth Accreditation',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: '% of Facilities with Packages' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'count',
    displayAsPercentage: true,
    stacked: true,
    xAxisDataKeys: [
      { key: '018A', displayName: 'Konsulta Package' },
      { key: '018B', displayName: 'Maternity Care Package' },
      { key: '018C', displayName: 'Newborn Care Package' },
      { key: '018D', displayName: 'Family Planning Package' },
      { key: '018E', displayName: 'Z-Benefit Package: Dialysis' },
      { key: '018F', displayName: 'OHAT' },
      { key: '018G', displayName: 'TB-DOTS' },
      { key: '018H', displayName: 'Animal Bite Treatment Center' },
      { key: '018I', displayName: 'Out-Patient Malaria Package' },
      { key: '018J', displayName: 'CCIBP' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.GREEN },
      { key: 'No', chartColor: COLORS.RED },
    ],
  },
  {
    type: 'bar',
    title: 'PhilHealth Accreditation',
    description: 'Number of Accredited Facilities per Facility Type',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'count',
    xAxisDataKeys: [
      { key: '018A', displayName: 'Konsulta Package' },
      { key: '018B', displayName: 'Maternity Care Package' },
      { key: '018C', displayName: 'Newborn Care Package' },
      { key: '018D', displayName: 'Family Planning Package' },
      { key: '018E', displayName: 'Z-Benefit Package: Dialysis' },
      { key: '018F', displayName: 'OHAT' },
      { key: '018G', displayName: 'TB-DOTS' },
      { key: '018H', displayName: 'Animal Bite Treatment Center' },
      { key: '018I', displayName: 'Out-Patient Malaria Package' },
      { key: '018J', displayName: 'CCIBP' },
    ],
    yAxisDataKeys: [{ key: 'Yes', chartColor: COLORS.BLUE }],
    yAxisBreakdown: {
      breakdownKey: 'Facility Type',
      yAxisBreakdownKeys: [
        { key: 'Rural Health Unit', chartColor: COLORS.GREEN },
        { key: 'Safe Birthing Facility', chartColor: COLORS.PURPLE },
        { key: 'Infirmary', chartColor: COLORS.BLUE },
        { key: 'Barangay Health Station', chartColor: COLORS.ORANGE },
      ],
    },
  },
  {
    type: 'bar',
    title: 'Service Capability of RHU',
    label: { xAxisLabel: 'Services', yAxisLabel: '% of Facilities' },
    containerDimnesion: { colSpan: 12 },
    facilityTypeFilter: ['Rural Health Unit'],
    displayAsPercentage: true,
    stacked: true,
    xAxisDataKeys: [
      { key: 'Antenatal Care' },
      { key: 'Delivery Services' },
      { key: 'Postnatal Care' },
      { key: 'Newborn Care' },
      { key: 'Family Planning' },
      { key: 'Child Vaccination' },
      { key: 'Child Growth Monitoring Services' },
      { key: 'Child Preventive and Curative Care Services' },
      { key: 'Adolescent Health Services' },
      { key: 'Laboratory Services' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', displayName: 'Ready', chartColor: COLORS.GREEN }, 
      { key: 'No', displayName: 'Not Ready', chartColor: COLORS.RED }
    ],
    dataPreprocessor: (data) => {
      const antenatalCareAvailableFields = [
        '084A', '084B', '084C', '084D', '084E', '084F', '084G', '084H', '084I', 
        '084J', '084K', '084L', '084M', '086A', '086B', '086C', '086D'
      ];
      const antenatalCareYesFields = ['087', '088'];
      
      const deliveryAvailableFields = [
        '089A', '089B', '089C', '089D', '089E', '089F', '089G', '089H', '089I', 
        '089J', '089K', '089L', '089M', '089N', '089O', '089P', '089Q', '089R', '089S'
      ];
      const deliveryYesFields = ['090A', '090B', '090C'];
      
      const postnatalCareYesFields = [
        '091A', '091B', '091C', '091D', '091E', '091F', '091G', '091H', '091I', 
        '091J', '092A', '092B', '092C'
      ];
      
      const newbornCareYesFields = [
        '093A', '093B', '093C', '093D', '093E', '093F', '093G', '093H', '093I', 
        '093J', '093K', '093L', '093M', '093N', '093O', '094'
      ];
      
      const facilityPlanningYesFields = [
        '095A', '095B', '095C', '095D', '095E', '095F', '095G', '095H', '095I', 
        '096', '097'
      ];
      
      const childVaccinationYesFields = [
        '098A', '098B', '098C', '098D', '098E', '098F', '098G', '098H', '100', '101', '102'
      ];
      
      const childMonitoringYesFields = ['103A', '103B', '103C', '105', '106'];
      
      const childPreventiveYesFields = [
        '107A', '107B', '107C', '107D', '107E', '107F', '107G', '107H', '107I', 
        '107J', '107K', '107L', '109A', '109B', '109C', '109D', '109E', '110'
      ];
      
      const adolescentHealthYesFields = ['111A', '111B', '111C', '111D', '111E', '112A', '112B', '112C', '113'];
      
      const laboratoryAvailableFields = [
        '114A', '114B', '114C', '114D', '114E', '114F', '114G', '114H', '114I', 
        '114J', '114K', '114L', '114M', '114N', '114O'
      ];
      

      const getValue = (row: any, yesFields: string[], availableFields?: string[]) => {
        let count = 0;
        let totalIndicators = yesFields.length + (availableFields ? availableFields.length : 0);
        count += yesFields.reduce((result, field) => {
          return result + (row[field] === 'No' ? 0 : 1);
        }, 0);
        if (availableFields) {
          count += availableFields.reduce((result, field) => {
            return result + (row[field] === 'Not Available' ? 0 : 1);
          }, 0);
        }
        // 100% Yes or Available
        return count === totalIndicators ? 'Yes' : 'No';
      };

      data.forEach((row) => {
        row['Antenatal Care'] = getValue(row, antenatalCareYesFields, antenatalCareAvailableFields);
        row['Postnatal Care'] = getValue(row, postnatalCareYesFields);
        row['Newborn Care'] = getValue(row, newbornCareYesFields);
        row['Family Planning'] = getValue(row, facilityPlanningYesFields);
        row['Child Vaccination'] = getValue(row, childVaccinationYesFields);
        row['Child Growth Monitoring Services'] = getValue(row, childMonitoringYesFields);
        row['Child Preventive and Curative Care Services'] = getValue(row, childPreventiveYesFields);
        row['Adolescent Health Services'] = getValue(row, adolescentHealthYesFields);
        row['Laboratory Services'] = getValue(row, [], laboratoryAvailableFields);

        // For RHU with SBF only
        row['Delivery Services'] =
          row[deliveryAvailableFields[0]] === 'N/A'
            ? 'Not Applicable'
            : getValue(row, deliveryYesFields, deliveryAvailableFields);
      });
      return data;
    },
  },
  {
    type: 'table',
    title: 'Health Workforce',
    description: <Typography>Standard: Doctor 1:20,000; Nurse 1:10,000; Midwife 1:5,000; Dentist 1:50,000; BHW 1:20 HH; Pharmacist 1:20,000; IT 1 per facility</Typography>,
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12, rowSpan: 3 },
    facilityTypeFilter: ['Rural Health Unit'],
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: 'Population', textAlign: 'right', formatValue: (value) => formatNumber(value, 0) },
      { key: 'Household', textAlign: 'right', formatValue: (value) => formatNumber(value, 0) },
      { key: 'Doctors', textAlign: 'right', formatValue: (value) => formatNumber(value, 0) },
      { key: 'Nurses', textAlign: 'right', formatValue: (value) => formatNumber(value, 0) },
      { key: 'Midwives', textAlign: 'right', formatValue: (value) => formatNumber(value, 0) },
      { key: 'Dentists', textAlign: 'right', formatValue: (value) => formatNumber(value, 0) },
      { key: 'Pharmacists', textAlign: 'right', formatValue: (value) => formatNumber(value, 0) },
      { key: 'IT', textAlign: 'right', formatValue: (value) => formatNumber(value, 0) },
      { key: 'BHWs', textAlign: 'right', formatValue: (value) => formatNumber(value, 0) },

      { key: 'Doctor-Population Ratio', textAlign: 'center' },
      { key: 'Nurse-Population Ratio', textAlign: 'center' },
      { key: 'Midwife-Population Ratio', textAlign: 'center' },
      { key: 'Dentist-Population Ratio', textAlign: 'center' },
      { key: 'Pharmacist-Population Ratio', textAlign: 'center' },
      { key: 'BHW-Household Ratio', textAlign: 'center' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    conditionalBarColorGetter: (value, extra) => {
      const getRatio = (str: string) => {
        let parts = str.split(':');
        if (parts.length > 1) {
          let numberStr = parts[1].replace(/,/g, '');
          return parseInt(numberStr, 10);
        }
        return 100000000000000
      }
      
      if (extra.key === 'Doctor-Population Ratio') {
        const _value = getRatio(value + "");
        return _value > 20000 ? COLORS.RED : COLORS.GREEN
      }
      if (extra.key === 'Nurse-Population Ratio') {
        const _value = getRatio(value + "");
        return _value > 10000 ? COLORS.RED : COLORS.GREEN
      }
      if (extra.key === 'Midwife-Population Ratio') {
        const _value = getRatio(value + "");
        return _value > 5000 ? COLORS.RED : COLORS.GREEN
      }
      if (extra.key === 'Dentist-Population Ratio') {
        const _value = getRatio(value + "");
        return _value > 50000 ? COLORS.RED : COLORS.GREEN
      }
      if (extra.key === 'Pharmacist-Population Ratio') {
        const _value = getRatio(value + "");
        return _value > 20000 ? COLORS.RED : COLORS.GREEN
      }
      if (extra.key === 'BHW-Household Ratio') {
        const _value = getRatio(value + "");
        return _value > 20 ? COLORS.RED : COLORS.GREEN
      }
      if (extra.key === 'IT') {
        return value < 1 ? COLORS.RED : COLORS.GREEN
      }
      return ''
    },
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'Population',
        'Household',
        'Doctors',
        'Doctor-Population Ratio',
        'Nurses',
        'Nurse-Population Ratio',
        'Midwives',
        'Midwife-Population Ratio',
        'Pharmacists',
        'Pharmacist-Population Ratio',
        'Dentists',
        'Dentist-Population Ratio',
        'BHWs',
        'BHW-Household Ratio',
        'IT',
      ],
      sortBy: 'Facility Name',
    },
    dataPreprocessor: (data) => {
      const doctorSourceFields = ['128A-1', '128A-2', '128A-3', '128A-4', '128A-5', '128A-6', '128A-8'];
      const nursesSourceFields = ['128B-1', '128B-2', '128B-3', '128B-4', '128B-5', '128B-6', '128B-8'];
      const midwivesSourceFields = ['128C-1', '128C-2', '128C-3', '128C-4', '128C-5', '128C-6', '128C-8'];
      const dentistsSourceFields = ['128E-1', '128E-2', '128E-3', '128E-4', '128E-5', '128E-6', '128E-8'];
      const pharmacistsSourceFields = ['128K-1', '128K-2', '128K-3', '128K-4', '128K-5', '128K-6', '128K-8'];
      const itSourceFields = ['128H-1', '128H-2', '128H-3', '128H-4', '128H-5', '128H-6', '128H-8'];
      const bhwSourceFields = ['129A-1', '129A-2'];
      
      const counter = (row: any, sourceFields: string[]) => {
        return sourceFields.reduce((result, field) => {
          const response = row[field];
          return result + (isNaN(parseFloat(response)) ? 0 : parseFloat(response));
        }, 0);
      };

      const getRatio = (count: number, population: number) => {
        return count ? `1 : ${formatNumber(population / count, 0)}` : 'N/A';
      };

      data.forEach((row) => {
        row['Doctors'] = counter(row, doctorSourceFields);
        row['Nurses'] = counter(row, nursesSourceFields);
        row['Midwives'] = counter(row, midwivesSourceFields);
        row['Dentists'] = counter(row, dentistsSourceFields);
        row['Pharmacists'] = counter(row, pharmacistsSourceFields);
        row['IT'] = counter(row, itSourceFields);
        row['BHWs'] = counter(row, bhwSourceFields);
      

        let projectedPopulation = parseInt((row['007B'] + "").replaceAll(',', '').replaceAll(' ', ''))
        let actualPopulation = parseInt((row['007D'] + "").replaceAll(',', '').replaceAll(' ', ''))
        let projectedHousehold = parseInt((row['007C'] + "").replaceAll(',', '').replaceAll(' ', ''))
        let actualHousehold = parseInt((row['007E'] + "").replaceAll(',', '').replaceAll(' ', ''))

        let population = actualPopulation > 0 ? actualPopulation : projectedPopulation;
        let household = actualHousehold > 0 ? actualHousehold : projectedHousehold;
        row['Population'] = population;
        row['Household'] = household;

        row['Doctor-Population Ratio'] = getRatio(row['Doctors'], population);
        row['Nurse-Population Ratio'] = getRatio(row['Nurses'], population);
        row['Midwife-Population Ratio'] = getRatio(row['Midwives'], population);
        row['Pharmacist-Population Ratio'] = getRatio(row['Pharmacists'], population);
        row['Dentist-Population Ratio'] = getRatio(row['Dentists'], population);
        row['BHW-Household Ratio'] = getRatio(row['BHWs'], household);
      });
      return data;
    },
  },
  {
    type: 'table',
    title: 'Health Worker Training',
    description: <Typography>For each training program under the category, at least one facility health worker has been trained</Typography>,
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12, rowSpan: 3 },
    facilityTypeFilter: ['Rural Health Unit'],
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: 'Antenatal Care', textAlign: 'center' },
      { key: 'Delivery Services', textAlign: 'center' },
      { key: 'Postnatal Care', textAlign: 'center' },
      { key: 'Family Planning', textAlign: 'center' },
      { key: 'Child Health Services', textAlign: 'center' },
      { key: 'Adolescent Health Services', textAlign: 'center' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    conditionalBarColorGetter: (value, extra) => {
      
      if (extra.key !== 'Facility Name') {
        const _value = value + "";
        return _value === 'No' ? COLORS.ORANGE : COLORS.GREEN
      }
      return ''
    },
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'Antenatal Care',
        'Delivery Services',
        'Postnatal Care',
        'Family Planning',
        'Child Health Services',
        'Adolescent Health Services',
      ],
      sortBy: 'Facility Name',
    },
    dataPreprocessor: (data) => {
      const antenatalCareYesFields    = ['134A-7', '134B-7', '134C-7', '134D-7', '135A-7', '135B-7', '135C-7', '136A-7', '136B-7', '137A-7'];
      const deliveryAvailableFields   = ['138A-7', '138B-7', '138C-7', '138D-7', '139A-7', '139B-7', '139C-7', '139D-7'];
      const postnatalCareYesFields    = ['140A-7', '140B-7'];
      const familyPlanningYesFields   = ['141A-7', '141B-7', '141C-7', '141D-7', '141E-7', '142A-7'];
      const childVaccinationYesFields = ['143A-7', '143B-7', '143C-7', '143D-7', '143E-7', '143F-7', '143G-7', '143H-7', '143I-7', '143J-7'];
      const adolescentHealthYesFields = ['144A-7', '144B-7', '144C-7', '144D-7', '144E-7'];

      const getValue = (row: any, yesFields: string[]) => {
        let totalIndicators = yesFields.length;
        let count = yesFields.reduce((result, field) => {
          const response = row[field];
          const total = isNaN(parseFloat(response)) ? 0 : parseFloat(response);
          return result + (total > 0 ? 1 : 0);
        }, 0);

        // 100% Yes or Available
        return count === totalIndicators ? 'Yes' : 'No';
      };

      data.forEach((row) => {
        row['Antenatal Care'] = getValue(row, antenatalCareYesFields);
        row['Delivery Services'] = getValue(row, deliveryAvailableFields);
        row['Postnatal Care'] = getValue(row, postnatalCareYesFields);
        row['Family Planning'] = getValue(row, familyPlanningYesFields);
        row['Child Health Services'] = getValue(row, childVaccinationYesFields);
        row['Adolescent Health Services'] = getValue(row, adolescentHealthYesFields);
      });
      return data;
    },
  },
  {
    type: 'bar',
    title: 'Total Budget for Health',
    label: { xAxisLabel: 'Municipality', yAxisLabel: 'Budget' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'sum',
    displayAsMultipleOfN: 1000000,
    displayDecimalPlaces: 2,
    facilityTypeFilter: ['Rural Health Unit'],
    xAxisDataKeys: [{ key: 'Municipality' }],
    useXAxisDataKeyResponseAsXAxis: true,
    yAxisDataKeys: [
      { key: '146A', displayName: 'Total Budget', chartColor: COLORS.BLUE },
    ],
  },
  {
    type: 'bar',
    title: 'Budget for Health (Per Program)',
    label: { xAxisLabel: 'Municipality', yAxisLabel: 'Budget' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'sum',
    displayAsMultipleOfN: 1000000,
    displayDecimalPlaces: 2,
    facilityTypeFilter: ['Rural Health Unit'],
    xAxisDataKeys: [{ key: 'Municipality' }],
    useXAxisDataKeyResponseAsXAxis: true,
    stacked: true,
    yAxisDataKeys: [
      { key: '146B', displayName: 'Safe Motherhood', chartColor: COLORS.BLUE },
      { key: '146C', displayName: 'Family Planning', chartColor: COLORS.RED },
      { key: '146D', displayName: 'Child Health', chartColor: COLORS.GREEN },
      { key: '146E', displayName: 'Nutrition', chartColor: COLORS.ORANGE },
      { key: '146F', displayName: 'Adolescent Health', chartColor: COLORS.PURPLE },
      { key: '146G', displayName: 'Oral Health', chartColor: COLORS.YELLOW },
      { key: '146H', displayName: 'ICT / EMR', chartColor: '#a4de6c' },
      { key: '146I', displayName: 'Other programs', chartColor: '#54BAD1' },
    ],
  },
  {
    type: 'table',
    title: 'Medicine Availability',
    label: { xAxisLabel: '', yAxisLabel: '' },
    containerDimnesion: { colSpan: 12, rowSpan: 3 },
    facilityTypeFilter: ['Rural Health Unit', 'Safe Birthing Facility'],
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: 'Antenatal Care', type: 'percentage' },
      { key: 'Delivery Services', type: 'percentage' },
      { key: 'Postnatal Care', type: 'percentage' },
      { key: 'Newborn Care', type: 'percentage' },
      { key: 'Family Planning', type: 'percentage' },
      { key: 'Child Vaccination', type: 'percentage' },
      { key: 'Child Preventive and Curative Care Services', type: 'percentage' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
    tableColumnBreakdown: [{key: 'Count', textAlign: 'right'}, {key: 'Total', textAlign: 'right'}, {key: 'Percentage', textAlign: 'right'}],
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'Antenatal Care',
        'Delivery Services',
        'Postnatal Care',
        'Newborn Care',
        'Family Planning',
        'Child Vaccination',
        'Child Preventive and Curative Care Services',
      ],
      sortBy: 'Facility Name',
    },
    dataPreprocessor: (data) => {
      const antenatalCareMedicines = [
        '149A-1', '149B-1', '149C-1', '149D-1', '149E-1', '149F-1', '149G-1', '149H-1', '149I-1', 
        '149J-1', '149K-1', '149L-1', '149M-1', '149N-1', '149O-1', '149P-1', '149Q-1'
      ];
      const deliveryMedicines = [
        '150A-1', '150B-1', '150C-1', '150D-1', '150E-1', '150F-1', '150G-1', '150H-1', '150I-1', 
        '150J-1', '150K-1', '150L-1', '150M-1', '150N-1', '150O-1', '150P-1', '150Q-1', '150R-1', 
        '150S-1', '150T-1', '150U-1', '150V-1', '150W-1', '150X-1', '150Y-1', '150Z-1', '150AA-1', 
        '150AB-1', '150AC-1', '150AD-1', '150AE-1', '150AF-1', '150AG-1', '150AH-1', '150AI-1', 
        '150AJ-1', '150AK-1', '150AL-1', '150AM-1', '150AN-1', '150AO-1', '150AP-1', '150AQ-1', 
        '150AR-1', '150AS-1', '150AT-1', '150AU-1', '150AV-1', '150AW-1'
      ];
      const postnatalCareMedicines = ['151A-1', '151B-1', '151C-1'];
      const newbornCareMedicines = [
        '152A-1', '152B-1', '152C-1', '152D-1', '152E-1', '152F-1', '152G-1', '152H-1', '152I-1', 
        '152J-1', '152K-1', '152L-1', '152M-1', '152N-1'
      ];
      const familyPlanningCareMedicines = [
        '153A-1', '153B-1', '153C-1', '153D-1', '153E-1', '153F-1', '153G-1', '153H-1', '153I-1', 
        '153J-1', '153K-1', '153L-1'
      ];
      const childVaccinationCareMedicines = [
        '154A-1', '154B-1', '154C-1', '154D-1', '154E-1', '154F-1', '154G-1', '154H-1', '154I-1', 
        '154J-1', '154K-1'
      ];
      const childPreventiveCareMedicines = [
        '155A-1', '155B-1', '155C-1', '155D-1', '155E-1', '155F-1', '155G-1', '155H-1', '155I-1', 
        '155J-1', '155K-1', '155L-1', '155M-1', '155N-1', '155O-1', '155P-1', '155Q-1', '155R-1', 
        '155S-1', '155T-1', '155U-1', '155V-1', '155W-1', '155X-1', '155Y-1', '155Z-1', '155AA-1', 
        '155AB-1', '155AC-1', '155AD-1', '155AE-1', '155AF-1', '155AG-1', '155AH-1', '155AI-1', 
        '155AJ-1', '155AK-1', '155AL-1', '155AM-1', '155AN-1', '155AO-1', '155AP-1', '155AQ-1', 
        '155AR-1', '155AS-1', '155AT-1', '155AU-1', '155AV-1', '155AW-1', '155AX-1', '155AY-1', 
        '155AZ-1', '155BA-1', '155BB-1', '155BC-1', '155BD-1', '155BE-1', '155BF-1', '155BG-1', 
        '155BH-1', '155BI-1', '155BJ-1'
      ];

      const getValue = (row: any, medicines: string[]) => {
        let totalIndicators = medicines.length;
        const count = medicines.reduce((result, field) => {
          return (
            result + (row[field] === 'Available, but expired' || row[field] === 'No stock (Prescribe or Refer)' ? 0 : 1)
          );
        }, 0);

        return {Percentage: `${formatNumber((count / totalIndicators) * 100, 0)}%`, Total: totalIndicators, Count: count};
      };

      data.forEach((row) => {
        row['Antenatal Care'] = getValue(row, antenatalCareMedicines);
        row['Postnatal Care'] = getValue(row, postnatalCareMedicines);
        row['Newborn Care'] = getValue(row, newbornCareMedicines);
        row['Family Planning'] = getValue(row, familyPlanningCareMedicines);
        row['Child Vaccination'] = getValue(row, childVaccinationCareMedicines);
        row['Child Preventive and Curative Care Services'] = getValue(row, childPreventiveCareMedicines);

        // For RHU with SBF only
        row['Delivery Services'] = row[deliveryMedicines[0]] === 'N/A' ? {Percentage: 'N/A', Total: 'N/A', Count: 'N/A'} : getValue(row, deliveryMedicines);
      });
      return data;
    },
    conditionalBarColorGetter: (value, extra) => {
      if (extra.key === "Percentage") {
        value = parseFloat(value + "")
        if (value >= 90) return COLORS.GREEN;
        if (value >= 50) return COLORS.YELLOW;
        return value + "" !== "N/A" ? COLORS.RED : "";
      }
      return '';
    },
  },
  {
    type: 'bar',
    title: 'Presence of DOH Policies in RHUs',
    label: { xAxisLabel: 'DOH Policies', yAxisLabel: '% of Facilities' },
    containerDimnesion: { colSpan: 12 },
    facilityTypeFilter: ['Rural Health Unit'],
    displayAsPercentage: true,
    stacked: true,
    xAxisDataKeys: [
      { key: 'Maternal Care' },
      { key: 'Oral Health' },
      { key: 'Newborn, Child, and Adolescent Health' },
      { key: 'Nutrition' },
      { key: 'Population-based Primary Care Services' },
      { key: 'Individual-based Primary Care Services' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: COLORS.BLUE },
      { key: 'No', chartColor: COLORS.RED },
    ],
    dataPreprocessor: (data) => {
      const maternalCareYesFields   = ['012A', '012B', '012C', '012D', '012E', '012F', '012G', '012H', '012I', '012J'];
      const oralHealthYesFields     = ['013A', '013B', '013C'];
      const ncaHealthYesFields      = ['014A', '014B', '014C', '014D', '014E'];
      const nutritionYesFields      = ['015A', '015B', '015C', '015D', '015E'];
      const populationCareYesFields = ['016A', '016B', '016C', '016D'];
      const individualCareYesFields = ['017A', '017B', '017C', '017D', '017E', '017F', '017G'];

      const getValue = (row: any, yesFields: string[]) => {
        let totalIndicators = yesFields.length;
        let count = yesFields.reduce((result, field) => {
          return result + (row[field] === 'No' ? 0 : 1);
        }, 0);

        // 100% Yes or Available
        return count === totalIndicators ? 'Yes' : 'No';
      };

      data.forEach((row) => {
        row['Maternal Care'] = getValue(row, maternalCareYesFields);
        row['Oral Health'] = getValue(row, oralHealthYesFields);
        row['Newborn, Child, and Adolescent Health'] = getValue(row, ncaHealthYesFields);
        row['Nutrition'] = getValue(row, nutritionYesFields);
        row['Population-based Primary Care Services'] = getValue(row, populationCareYesFields);
        row['Individual-based Primary Care Services'] = getValue(row, individualCareYesFields);
      });
      return data;
    },
  },
  {
    type: 'bar', //converted to table
    title: 'EMR Functionality Status of RHUs',
    convertToTable: true,
    label: { xAxisLabel: 'EMR Functionality', yAxisLabel: '% of Facilities' },
    aggregation: 'count',
    displayAsPercentage: true,
    facilityTypeFilter: ['Rural Health Unit'],
    xAxisDataKeys: [
      { key: '169A', displayName: 'Patients Consultation Recording' },
      { key: '169B', displayName: 'Inventory of Logistics' },
      { key: '169C', displayName: 'Environmental' },
      { key: '169D', displayName: 'Financial for PhilHealth Claims' },
      { key: '171A', displayName: 'FHSIS Report Submission' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', displayName: 'Count', chartColor: COLORS.BLUE, textAlign: 'right' },
    ],
    conditionalBarColorGetter: (value, extra) => {
      if (extra.key === "Percentage") {
        value = parseFloat(value + "")
        if (value >= 80) return COLORS.GREEN;
        if (value >= 50) return COLORS.YELLOW;
        if (value >= 25) return COLORS.ORANGE;
        return COLORS.RED;
      }
      return '';
    },
  },
  {
    type: 'bar',
    title: 'DOH Licenses',
    label: { xAxisLabel: 'DOH Licenses', yAxisLabel: 'No. of Facilities' },
    stacked: true,
    displayAsPercentage: true,
    xAxisDataKeys: [
      { key: '201', displayName: 'Safe Birthing Facility' },
      { key: '202', displayName: 'Pharmacy' },
      { key: '203', displayName: 'Radiologic Services' },
      { key: '204', displayName: 'Ambulance' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', displayName: 'Compliant', chartColor: COLORS.BLUE },
      { key: 'No', displayName: 'Non-compliant',chartColor: COLORS.RED },
      { key: 'Service outsourced', chartColor: COLORS.GREEN },
    ],
  },
  {
    type: 'bar',
    title: 'Outcome Indicators for Mortality',
    label: { xAxisLabel: 'Mortality', yAxisLabel: 'No. of Deaths' },
    aggregation: 'sum',
    xAxisDataKeys: [
      { key: '190', displayName: 'Maternal Deaths' },
      { key: '192', displayName: 'Neonatal Deaths' },
      { key: '194', displayName: 'Infant Deaths (< 1 year of age)' },
      { key: '196', displayName: 'Child Deaths' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.BLUE }],
  },
  {
    type: 'bar',
    title: 'Outcome Indicators for Nutrition',
    label: { xAxisLabel: 'Nutrition', yAxisLabel: 'No. of Malnourished Children' },
    aggregation: 'sum',
    xAxisDataKeys: [
      { key: '197', displayName: '0-59 Months Wasting' },
      { key: '198', displayName: '0-59 Months SAM' },
      { key: '199', displayName: '0-59 Months Stunting' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: COLORS.GREEN }],
  },
  {
    type: 'bar',
    title: 'PCF Compliance Status of RHUs',
    label: { xAxisLabel: 'Facility Name', yAxisLabel: 'PCF Compliance' },
    containerDimnesion: { colSpan: 12 },
    facilityTypeFilter: ['Rural Health Unit'],
    xAxisDataKeys: [{ key: 'Facility Code', displayName: 'Facility Name' }],
    aggregation: 'sum',
    useXAxisDataKeyResponseAsXAxis: true,
    yAxisReferenceLine: {
      label: "Benchmark (90%)",
      yValue: 90,
    },
    addPercentSignOnly: true,
    yAxisDataKeys: [{ key: 'PCF Compliance Status', chartColor: COLORS.BLUE }],
    customTooltipFormatter: (value: any) => formatNumber(value) + '%',
    dataPreprocessor: (data) => {
      const pcf_indicators = pcf_items.flatMap((field) =>
        field.items.map((code: any) => {
          return { field_code: code, required_response: field.required_response ?? 'Yes' };
        })
      );
      const equipment = pcf_equipment.flatMap((item: any) =>
        item.equipment.flatMap((field: any) =>
          field.field_code.map((code: any) => {
            return { field_code: code, required_response: field.required_response ?? 'Available & Functional' };
          })
        )
      );
      const getValue = (row: any) => {
        let totalIndicators = equipment.length + pcf_indicators.length;
        let count = pcf_indicators.reduce((result, field) => {
          return result + (!row[field.field_code] || row[field.field_code] === field.required_response ? 1 : 0);
        }, 0);
        count += equipment.reduce((result, field) => {
          return result + (!row[field.field_code] || row[field.field_code] === field.required_response ? 1 : 0);
        }, 0);

        // 100% Yes or Available
        return (count / totalIndicators) * 100;
      };

      data.forEach((row) => {
        row['PCF Compliance Status'] = getValue(row);
      });
      return data;
    },
  },
];
