/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput, SelectOption } from 'model/interface';
import { AssessmentIndicatorModel, FormValidationWorkflowModel } from 'model/Entities';
import { Box, Typography, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  Header,
  PrimaryButton,
  RegularButton,
  RowAction,
} from 'components';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import {
  createAssessmentIndicators,
  deleteAssessmentIndicator,
  duplicateAssessmentIndicators,
  editAssessmentIndicator,
  getAssessmentIndicators,
  updateAssessmentIndicator,
} from 'api/assessment-indicator';
import { dark_vscode_tribute, light_mitsuketa_tribute, localeEn } from 'utils/json_editor';
import { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomTable from 'components/CustomTable';
import CustomTabs from 'components/CustomTabs';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { DropdownOptionProps } from 'components/Dropdown';
import IndicatorViewer from 'components/IndicatorViewer';
import JSONInput from 'react-json-editor-ajrm';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { getAllWorkflows } from 'api/form-validation-workflow';
import { getCurrentUser } from 'api/user';
import { useSnackbar } from 'notistack';

// const afhf_indicators = require('../../data/ReportingTool/main');
// const aklan_indicators = require('../../data/Aklan/main');
// const caraga_indicators = require('../../data/CARAGA/main');
// const ev_indicators = require('../../data/Eastern Visayas/main');
// const afhf3 = require('../../data/Eastern Visayas/afhf_level3');

const AssessmentIndicators = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [refresh, setRefresh] = useState(0);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [currentAssessmentIndicator, setCurrentAssessmentIndicator] = useState<any>();
  const [duplicateRow, setDuplicateRow] = useState<AssessmentIndicatorModel>();
  const [sections, setSections] = useState<any[]>([]);
  const [indicators, setIndicators] = useState('');
  const [indicatorName, setIndicatorName] = useState('');
  const [preview, setPreview] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [openEditDetailsModal, setOpenEditDetailsModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [error, setError] = useState(false);
  const [validationWorkflows, setValidationWorkflow] = useState<SelectOption[]>([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  type AssessmentIndicatorEditProps = {
    name: string;
    validation_workflow_id: number;
  };

  interface AssessmentIndicatorFieldInput extends FieldInput {
    field_name: keyof AssessmentIndicatorEditProps;
  }

  const initialValues: AssessmentIndicatorEditProps = {
    name: currentAssessmentIndicator?.name ?? '',
    validation_workflow_id: currentAssessmentIndicator?.validation_workflow_id ?? '',
  };

  const formFields: AssessmentIndicatorFieldInput[] = [
    {
      field_name: 'name',
      display_name: 'Name',
      type: 'text',
      span: 4,
      required: true,
    },
    {
      field_name: 'validation_workflow_id',
      display_name: 'Validation Workflow',
      type: 'select',
      required: true,
      span: 4,
      options: validationWorkflows,
    },
  ];

  // Table Columns
  const actions: DropdownOptionProps[] = [
    {
      label: 'Edit Details',
      startIcon: <DriveFileRenameOutlineIcon />,
      action: (data) => {
        setCurrentAssessmentIndicator(data);
        setOpenEditDetailsModal(true);
        setPreview(data.indicators);
      },
    },
    {
      label: 'Duplicate',
      startIcon: <ContentCopyIcon />,
      action: (data) => {
        setDuplicateRow(data);
        setOpenDuplicateModal(true);
      },
    },
    // {
    //   label: 'Edit Indicator',
    //   action: (data) => navigate(`/indicators/editor/${data.id}`),
    //   startIcon: <DynamicFormOutlinedIcon />,
    // },
    {
      label: 'Preview',
      startIcon: <RemoveRedEyeIcon />,
      action: (data) => {
        setSelectedTab(0);
        setOpen(true);
        setPreview(data.indicators);
        setIndicatorName(data.name);
      },
    },
    {
      label: 'Delete',
      action: (data) => {
        setCurrentAssessmentIndicator(data);
        setOpenDeleteConfirmation(true);
      },
      startIcon: <DeleteOutlinedIcon />,
    },
    // {
    //   label: 'Edit Dashboard',
    //   action: (data) => navigate(`/dashboard-editor/${data.id}`),
    //   startIcon: <DashboardCustomizeIcon />,
    // },
  ];

  const columns: GridColDef[] = [
    {
      field: 'name',
      sortable: false,
      headerName: 'Name',
      flex: 1.5,
    },
    {
      field: 'steps',
      sortable: false,
      headerName: 'Validation Process',
      width: 200,
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.form_validation_workflow?.steps
            ? JSON.parse(params.row.form_validation_workflow.steps).join(' → ')
            : '-'}
        </CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  // Functions
  const getIndicators = (query?: ApiQuery) => {
    setLoading(true);
    getAssessmentIndicators({ page: 1, length: 10, ...query })
      .then((res) => {
        setRows(res.data.rows);
        setRowCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSelectRow = (selected: GridSelectionModel) => {
    if (selected.length && selectedRow?.id !== selected[0]) {
      const row = rows.find((row) => row.id === (selected[0] as number));
      setSelectedRow(row);
    } else {
      setSelectedRow(undefined);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoadingSave(true);
      await updateAssessmentIndicator(selectedRow.id, indicators);
      enqueueSnackbar('Indicator successfully updated!', { variant: 'success' });
      setRefresh((prev) => prev + 1);
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    } finally {
      setLoadingSave(false);
    }
  };

  const handleAddSection = () => {
    const count = sections.length;
    const body = {
      section_number: count,
      section_name: 'New Section',
      section_body: [
        {
          type: 'field',
          field_name: 'New Field',
          field_code: '1',
          response_type: 'text',
        },
      ],
    };
    setSections((prev) => [...prev, body]);

    const prev = JSON.parse(indicators);
    prev.push(body);
    setIndicators(JSON.stringify(prev));
    setSelectedTab(prev.length - 1);
  };

  const handleRemoveSection = () => {
    const prev = JSON.parse(indicators);
    if (prev.length > 1) {
      const last = prev.pop();
      if (selectedTab === last.section_number) setSelectedTab(prev.length - 1);
      setIndicators(JSON.stringify(prev));

      setTimeout(() => {
        sections.pop();
        setSections([...sections]);
      }, 200);
    }
  };

  const handleEditDetails = async (data: any) => {
    try {
      setButtonLoading(true);
      currentAssessmentIndicator &&
        editAssessmentIndicator(currentAssessmentIndicator.id, data.name, data.validation_workflow_id).then(() => {
          enqueueSnackbar('Edit successful!', { variant: 'success' });
          setRefresh((prev) => prev + 1);
        });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Edit unsuccessful!', { variant: 'error' });
    } finally {
      setOpenEditDetailsModal(false);
      setButtonLoading(false);
    }
  };

  const handleCreate = async (data: any) => {
    try {
      const user = await getCurrentUser();
      const region_code = user.data.region_code;
      data.region_code = region_code;
      createAssessmentIndicators(data).then(() => {
        enqueueSnackbar('Creation successful!', { variant: 'success' });
        setRefresh((prev) => prev + 1);
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Creation unsuccessful!', { variant: 'error' });
    } finally {
      setOpenCreateModal(false);
    }
  };
  const handleDuplicate = async () => {
    try {
      duplicateRow &&
        duplicateAssessmentIndicators(duplicateRow.id!).then(() => {
          enqueueSnackbar('Duplication successful!', { variant: 'success' });
          setRefresh((prev) => prev + 1);
        });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Duplication unsuccessful!', { variant: 'error' });
    } finally {
      setOpenDuplicateModal(false);
    }
  };

  const handleDeleteConfirm = async () => {
    if (currentAssessmentIndicator.id) {
      deleteAssessmentIndicator(parseInt(currentAssessmentIndicator.id))
        .then(() => {
          setRefresh((prev) => prev + 1);
          enqueueSnackbar('Delete successful!', { variant: 'success' });
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar('Delete unsuccessful!', { variant: 'error' });
        })
        .finally(() => {
          setOpenDeleteConfirmation(false);
          setCurrentAssessmentIndicator(null);
        });
    }
  };

  useEffect(() => {
    if (selectedRow) {
      setIndicators(selectedRow.indicators);
      setSections(JSON.parse(selectedRow.indicators));
    } else {
      setIndicators('');
      setSections([]);
    }
    // setIndicators(JSON.stringify(afhf3));
    // setSections(afhf3);
  }, [selectedRow]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Indicators' }]);

    getAllWorkflows({ length: 1000 }).then((res) => {
      setValidationWorkflow(
        res.data.rows.map((workflow: FormValidationWorkflowModel) => {
          return { value: workflow.id, key: `${workflow.name}  (${JSON.parse(workflow.steps ?? '[]').join(' → ')})` };
        })
      );
    });
  }, []);

  return (
    <Box>
      <CustomContainer>
        <CustomTable
          searchKeys="Assessment Name"
          headerComponent={
            <Box gap="15px" display="flex" flexDirection="column" alignItems="flex-start">
              <Header title="Assessment Indicators" subtitle="Click row to view indicators" mb="20px" />
              <PrimaryButton
                onClick={() => {
                  setCurrentAssessmentIndicator(undefined);
                  setOpenCreateModal(true);
                }}
                label={'Add Indicators'}
              />
            </Box>
          }
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          getData={getIndicators}
          isRowSelectable
          handleSelectRow={handleSelectRow}
          forceRefresh={refresh}
        />

        <CustomModal header="Add Indicators" open={openCreateModal} setOpen={setOpenCreateModal} width={500}>
          <CustomForm
            initialValues={initialValues}
            onSubmit={handleCreate}
            fields={formFields}
            loading={buttonLoading}
          />
        </CustomModal>
      </CustomContainer>

      {selectedRow && (
        <Box sx={{ marginTop: '50px' }}>
          <Header title={`Edit - ${selectedRow.name}`} mb="15px" />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: '10px' }}>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <RegularButton
                styles={{ width: '160px' }}
                label="Add Section"
                onClick={handleAddSection}
                startIcon={<AddIcon />}
              />
              <RegularButton
                styles={{ width: '160px' }}
                color="error"
                label="Remove Last Section"
                onClick={handleRemoveSection}
                startIcon={<RemoveIcon />}
                disabled={sections.length <= 1}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <RegularButton
                styles={{ width: '120px' }}
                label="Preview"
                onClick={() => {
                  setOpen(true);
                  setPreview(indicators);
                }}
                startIcon={<RemoveRedEyeIcon />}
              />
              <RegularButton
                styles={{ width: '120px' }}
                label="Save"
                loading={loadingSave}
                disabled={error}
                onClick={() => setOpenSaveConfirmation(true)}
                startIcon={<CheckIcon />}
              />
            </Box>
          </Box>

          <Typography variant="body1" height="30px" textAlign="center">
            {showMessage
              ? `Your changes can now be saved. Feel free to click the 'Save' button whenever you're ready to save them.`
              : ''}
          </Typography>

          <CustomTabs
            selected={selectedTab}
            setSelected={setSelectedTab}
            tabs={sections.map((section: any, index) => {
              return {
                label: 'Section ' + section.section_number,
                content: (
                  <JSONInput
                    id={`section-${index}`}
                    placeholder={section}
                    colors={theme.palette.mode === 'light' ? light_mitsuketa_tribute : dark_vscode_tribute}
                    locale={localeEn}
                    height="calc(100vh - 180px)"
                    width="100%"
                    onChange={(value: any) => {
                      setShowMessage(true);
                      setTimeout(() => {
                        setShowMessage(false);
                      }, 5000);
                      setError(!!value.error);
                      if (!value.error) {
                        const newSections = sections.map((sec: any) =>
                          sec.section_number === section.section_number ? JSON.parse(value.json) : sec
                        );
                        setIndicators(JSON.stringify(newSections));
                      }
                    }}
                  />
                ),
              };
            })}
          />
        </Box>
      )}

      <CustomModal header={`${indicatorName} - Preview`} open={open} setOpen={setOpen} width={1080}>
        <IndicatorViewer init_sections={preview} defaultTab={selectedTab} fromPreview />
      </CustomModal>

      <CustomModal header="Edit Details" open={openEditDetailsModal} setOpen={setOpenEditDetailsModal} width={500}>
        <CustomForm
          initialValues={initialValues}
          onSubmit={handleEditDetails}
          fields={formFields}
          loading={buttonLoading}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openDuplicateModal}
        setOpen={setOpenDuplicateModal}
        message={duplicateRow ? `Are you sure want to duplicate ${duplicateRow.name}?` : ''}
        onConfirm={handleDuplicate}
      />

      <ConfirmationDialog
        open={openSaveConfirmation}
        setOpen={setOpenSaveConfirmation}
        message="Are you sure want to update the indicators?"
        onConfirm={handleUpdate}
      />

      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure you want to delete this indicator?"
        onConfirm={handleDeleteConfirm}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
      />
    </Box>
  );
};

export default AssessmentIndicators;
