/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'model/interface';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  CustomTable,
  RegularButton,
  RowAction,
} from 'components';
import { createWorkflow, deleteWorkflow, getAllWorkflows, updateWorkflow } from 'api/form-validation-workflow';
import { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { FormValidationWorkflowModel } from 'model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import Header from 'components/Header';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const formInitialValues = {
  name: '',
};

type WorkflowValidationInput = {
  name: string;
};

interface WorkflowValidationFieldInput extends FieldInput {
  field_name: keyof WorkflowValidationInput;
}

const ValidationWorkflow = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([{ label: 'Validation Workflow', link: '/validation-workflow' }]);
  }, []);

  const [openCreateWorkflowModal, setOpenCreateWorkflowModal] = useState(false);
  const [createWorkflowModalHeader, setCreateWorkflowModal] = useState('Create workflow');
  const [selectedValidationWorkflow, setSelectedValidationWorkflow] = useState(0);
  const [initialValues, setInitialValues] = useState<WorkflowValidationInput>(formInitialValues);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<FormValidationWorkflowModel[]>([]);
  const [deleteRow, setDeleteRow] = useState<any>();
  const [rowCount, setRowCount] = useState<number>(0);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const handleCreateWorkflow = () => {
    setInitialValues(formInitialValues);
    setOpenCreateWorkflowModal(true);
  };

  const handleCloseCreateWorkflowModal = () => {
    setOpenCreateWorkflowModal(false);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, flex: 0.2 },
    { field: 'name', headerName: 'Name', width: 200, flex: 2 },
    {
      field: 'steps',
      headerName: 'Process',
      width: 200,
      flex: 3,
      renderCell: (params) => (
        <CustomGridCell>{params.row.steps ? JSON.parse(params.row.steps).join(' → ') : '-'}</CustomGridCell>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const getData = (query?: ApiQuery) => {
    setLoading(true);
    getAllWorkflows({
      page: 1,
      length: 10,
      ...query,
    })
      .then((res) => {
        setRows(res.data.rows);
        setRowCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  const updateCurrentWorkflow = (data: any) => {
    navigate(`/validation-workflow/${data.id}`);
  };

  const renameWorkflow = (data: any) => {
    setCreateWorkflowModal('Rename Workflow');
    setInitialValues(data);
    setSelectedValidationWorkflow(data.id);
    setOpenCreateWorkflowModal(true);
  };

  const handleDelete = (data: any) => {
    setDeleteRow(data);
    setOpenDeleteConfirmation(true);
  };

  const handleDeleteConfirm = () => {
    deleteRow &&
      deleteWorkflow(deleteRow.id)
        .then(() => {
          enqueueSnackbar('Delete successful!', { variant: 'success' });
        })
        .catch((err: any) => {
          enqueueSnackbar('Error deleting workflow!', { variant: 'error' });
        })
        .finally(() => {
          setOpenDeleteConfirmation(false);
          setRefresh((prev: number) => prev + 1);
        });
  };

  const actions = [
    {
      label: 'Update Workflow',
      action: updateCurrentWorkflow,
      startIcon: <DriveFileRenameOutlineIcon />,
    },
    {
      label: 'Rename Workflow',
      action: renameWorkflow,
      startIcon: <DriveFileRenameOutlineIcon />,
    },
    {
      label: 'Delete',
      action: handleDelete,
      startIcon: <DeleteOutlinedIcon />,
    },
  ];

  const formFields: WorkflowValidationFieldInput[] = [
    {
      field_name: 'name',
      display_name: 'Name',
      type: 'text',
      required: true,
      span: 4,
    },
  ];

  const handleFormSubmit = (data: any, id: number) => {
    if (id) {
      updateWorkflow(id, { name: data.name })
        .then((res) => {
          enqueueSnackbar('Rename successful!', { variant: 'success' });
          setRefresh((prev: number) => prev + 1);
          setOpenCreateWorkflowModal(false);
        })
        .catch((error) => {
          enqueueSnackbar('Rename unsuccessful!', { variant: 'error' });
          setOpenCreateWorkflowModal(false);
        })
        .finally(() => {
          setSelectedValidationWorkflow(0);
        });
      return;
    }

    createWorkflow(data)
      .then((res) => {
        enqueueSnackbar('Create successful!', { variant: 'success' });
        setRefresh((prev: number) => prev + 1);
        setOpenCreateWorkflowModal(false);
        updateCurrentWorkflow(res.data);
      })
      .catch((error) => {
        enqueueSnackbar('Create unsuccessful!', { variant: 'error' });
      });
    return;
  };

  return (
    <Box>
      <CustomContainer>
        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          getData={getData}
          forceRefresh={refresh}
          headerComponent={
            <>
              <Header title="Validation Workflow" mb="30px" />
              <RegularButton
                label="Create Workflow"
                onClick={handleCreateWorkflow}
                variant="contained"
                color="primary"
              />
            </>
          }
          hideSearch
        />

        <CustomModal
          header={createWorkflowModalHeader}
          open={openCreateWorkflowModal}
          setOpen={setOpenCreateWorkflowModal}
          onClose={handleCloseCreateWorkflowModal}
          width={500}
        >
          <CustomForm
            onSubmit={(data) => handleFormSubmit(data, selectedValidationWorkflow)}
            fields={formFields}
            initialValues={initialValues}
            submitButtonText="Confirm"
          />
        </CustomModal>

        <ConfirmationDialog
          open={openDeleteConfirmation}
          setOpen={setOpenDeleteConfirmation}
          message="Are you sure you want to delete this workflow?"
          onConfirm={handleDeleteConfirm}
        />
      </CustomContainer>
    </Box>
  );
};

export default ValidationWorkflow;
