import ArticleContent, { imagePath } from '../ArticleContent';

const CRUDReportEntryArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          title: 'Creating a Report Entry',
          steps: [
            {
              step: '1. Select the row of the report, and click "Add Report Entry".',
              image: `${imagePath}/create-report-entry-1.png`,
            },
            {
              step: '2. Kindly fill up the Modal with the preferred Month and Deadline ("Assess all facilities" can be checked to add all the facilities to the report entry).',
            },
            { step: '3. Click "Submit".', image: `${imagePath}/create-report-entry-2.png` },
          ],
        },
        {
          title: 'Updating a Report Entry',
          divider: true,
          steps: [
            {
              step: '1. Click the action button on the right part, then click update".',
              image: `${imagePath}/update-report-entry-1.png`,
            },
            {
              step: '2. Kindly edit the values in the modal, then click "Submit".',
              image: `${imagePath}/update-report-entry-2.png`,
            },
          ],
        },
        {
          title: 'Deleting a Report Entry',
          divider: true,
          steps: [
            {
              step: '1. Select the action button on the right side of the report entry, then click "Delete".',
              image: `${imagePath}/delete-report-entry-1.png`,
            },
            {
              step: '2. A Confirmation dialog will show up, simply click "Yes" to delete.',
              image: `${imagePath}/delete-report-entry-2.png`,
            },
          ],
        },
        {
          title: 'Duplicating a Report Entry',
          divider: true,
          steps: [
            {
              step: '1. Select the action button on the right side of the report entry, then click "Duplicate".',
              image: `${imagePath}/duplicate-report-entry-1.png`,
            },
            {
              step: '2. A Modal will pop up with the same values as the selected report entry, kindly edit the values on the modal (if needed) then click "Submit".',
              image: `${imagePath}/duplicate-report-entry-2.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default CRUDReportEntryArticle;
