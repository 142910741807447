import ArticleContent, { imagePath } from '../ArticleContent';

const AddRemoveFacilitiesReportEntryArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          title: 'Report Entry page',
          steps: [
            {
              step: '1. On the reporting tool, select the report to expand the report entry drawer. On the report entry drawer, kindly click the hyperlinked month on the Period column',
              image: `${imagePath}/report-entry-page-1.png`,
            },
            {
              step: '2. After selecting the hyperlinked month, the page will redirect to the report entry of the month where you can add/remove facilities.',
              image: `${imagePath}/report-entry-page-2.png`,
            },
          ],
        },
        {
          title: 'Adding Facilities to Report Entry',
          steps: [
            { step: '1. Click the "Add Facility" button', image: `${imagePath}/add-facility-report-entry-1.png` },
            {
              step: '2. Check the faculties (by clicking the checkbox) to be added and click "Add Selected Facilities" button (the "Add" button on the action part can be also used to add individual facility).',
              image: `${imagePath}/add-facility-report-entry-2.png`,
            },
          ],
        },
        {
          title: 'Removing Facility to Report Entry',
          steps: [
            { step: '1. Click "Remove Facility" button.', image: `${imagePath}/remove-facility-report-entry-1.png` },
            {
              step: '2. In the table, the facilities added to the report entry can be seen. To remove the facilities, simply check the facilities to be removed then click "Remove Selected Faculties"',
              image: `${imagePath}/remove-facility-report-entry-2.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default AddRemoveFacilitiesReportEntryArticle;
