/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from '@mui/material';
import { CustomContainer, FacilityLocationFilter, RegularButton } from 'components';
import { DashboardWidget, getChartData } from './charts/chartDataProcessor';
import { Fragment, useContext, useEffect, useMemo } from 'react';

import { AssessmentBatchModel } from 'model/Entities';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomAutoCompleteSelect from 'components/CustomAutoComplete';
import LoadingScreen from 'screens/Helper/loading';
import { UserContext } from 'context/user.context';
import { getAssessmentBatches } from 'api/assessment-batch';
import { getBatchDashboardData } from 'api/assessment-batch-facilities';
import { useState } from 'react';

const Home = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);

  const [responses, setResponses] = useState<any[]>([]);
  const [assessmentBatches, setAssessmentBatches] = useState<any[]>([]);
  const [selectedBatch, setSelectedBatch] = useState(0);
  const [widgets, setWidgets] = useState<DashboardWidget[]>([]);
  const [loading, setLoading] = useState(false);
  const [locationFilters, setLocationFilters] = useState<any>({});

  useEffect(() => {
    setBreadcrumb([]);
  }, []);

  useEffect(() => {
    if (assessmentBatches.length) {
      if (localStorage.getItem('selectedBatch')) {
        const batch_id = localStorage.getItem('selectedBatch') as string;
        setSelectedBatch(parseInt(batch_id));
      } else {
        setSelectedBatch(assessmentBatches[0].id);
      }
    }
  }, [assessmentBatches]);

  const getResponses = async (selectedBatch: AssessmentBatchModel) => {
    try {
      setLoading(true);
      const res = await getBatchDashboardData(selectedBatch.id!, selectedBatch.indicator_id);
      setResponses(res.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedBatch) {
      const selected = assessmentBatches.find((batch) => batch.id === selectedBatch);
      if (selected) {
        const template = user.dashboard_templates.find(
          (template) => template.dashboard_code === selected.dashboard_code
        );
        if (template) {
          setWidgets(template.template);
          getResponses(selected);
          return;
        }
      }
    }
    setWidgets([]);
    setResponses([]);
    setLoading(false);
  }, [user, selectedBatch]);

  useEffect(() => {
    const fetchAssessmentBatches = async () => {
      try {
        setLoading(true);
        const response = await getAssessmentBatches({ length: 1000, status: ['active', 'closed'] });
        if (response && response.data && response.data.rows) {
          setAssessmentBatches(response.data.rows);
        }
      } catch (error) {
        console.error('Failed to fetch assessment batches:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessmentBatches();
  }, []);

  const handleBatchChange = (value: any) => {
    if (value) {
      const selectedValue = value.value;
      setSelectedBatch(selectedValue);
      localStorage.setItem('selectedBatch', selectedValue);
    } else {
      setSelectedBatch(0);
    }
  };

  const chart = useMemo(() => {
    return (
      <>
        {widgets.map((chart, index) => {
          return <Fragment key={index}>{getChartData(responses, chart, locationFilters)}</Fragment>;
        })}
      </>
    );
  }, [widgets, responses, locationFilters]);

  const clearFilter = () => {
    for (let key in locationFilters) {
      locationFilters[key] = '';
    }
    setLocationFilters({ ...locationFilters });
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box display="flex" flexDirection="column" gap="20px">
      <CustomContainer>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" gap="10px" width="100%" alignItems="center">
            <FacilityLocationFilter
              handleChangeFilter={(data) => setLocationFilters({ ...locationFilters, ...data })}
              otherFilters={locationFilters}
              showFacilityDropdown
              hideFacilityType
            />
            <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
          </Box>
          <Box width="350px">
            <CustomAutoCompleteSelect
              label="Assessment Batch"
              fieldName="assessment_batch"
              value={selectedBatch}
              handleChangeCallback={(value) => handleBatchChange(value)}
              options={assessmentBatches.map((batch) => ({
                key: batch.batch_name,
                value: batch.id,
              }))}
            />
          </Box>
        </Box>
      </CustomContainer>
      {selectedBatch ? (
        <>
          {widgets.length ? (
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="180px" gap="20px">
              {chart}
            </Box>
          ) : (
            <CustomContainer>
              <Typography variant="h5" fontWeight="bold">
                The selected batch has no dashboard.
              </Typography>
            </CustomContainer>
          )}
        </>
      ) : (
        <CustomContainer>
          <Typography variant="h5" fontWeight="bold">
            Please select a batch
          </Typography>
        </CustomContainer>
      )}
    </Box>
  );
};

export default Home;
