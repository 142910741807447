import { CARAGADashboard1 } from 'data/CARAGA/dashboard/CARAGA_V1/chartConfiguration_CARAGA';
import { DashboardTemplate } from '.';

export const caraga_dashboards: DashboardTemplate[] = [
  {
    dashboard_code: 'caraga_dashboard_1',
    dashboard_name: 'CARAGA FRESAA Dashboard',
    template: CARAGADashboard1,
  },
];
