import { Box, Typography, useTheme } from '@mui/material';

import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import React from 'react';
import { categories } from './categories';
import styled from 'styled-components';
import { tokens } from 'context/theme.context';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function HelpDeskSidebar() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { category, categoryCode } = useParams<{ category?: string; categoryCode?: string }>();

  const SidebarContainer = styled.div`
    width: 250px;
    min-width: 250px;
    background-color: ${colors.secondary_background};
    min-height: 88vh;
    overflow-y: auto;
    border-radius: 10px;
    padding: 20px;
  `;

  const CategoryLink = styled.button<{ isActive: boolean }>`
    display: block;
    background: none;
    border: none;
    text-decoration: none;
    color: ${(props) => (props.isActive ? colors.primary : '#333')};

    cursor: pointer;
    transition: 0.2s;
    font-weight: ${(props) => (props.isActive ? '600' : '500')};
    border-radius: 8px;

    &:hover {
      color: ${colors.secondary};
    }
    .arrow-icon {
      transition: 0.1s;
    }

    &:hover .arrow-icon {
      color: ${colors.secondary};
      transform: rotate(18deg);
    }
  `;
  return (
    <SidebarContainer>
      <Typography variant="h4" fontWeight="bold">
        Categories
      </Typography>
      <Box display="flex" flexDirection="column" gap="15px" mt="50px">
        {categories
          .filter((category) => !category.hidden)
          .map((cat, index) => (
            <CategoryLink
              key={index}
              isActive={cat.categoryCode === category || cat.categoryCode === categoryCode}
              onClick={() => navigate(`/helpdesk/${cat.categoryCode}`)}
            >
              <Box display="flex" gap="10px">
                <FolderOpenIcon
                  className="arrow-icon"
                  style={{
                    color: cat.categoryCode === category || cat.categoryCode === categoryCode ? colors.primary : '',
                  }}
                />
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: cat.categoryCode === category || cat.categoryCode === categoryCode ? 'bold' : 'normal',
                  }}
                >
                  {cat.categoryName}
                </Typography>
              </Box>
            </CategoryLink>
          ))}
      </Box>
    </SidebarContainer>
  );
}

export default HelpDeskSidebar;
